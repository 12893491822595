import React, { useEffect, useState } from "react";
import {
    Button,
    Flex,
    Grid,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { SearchLogo } from "../../assets/SearchIcon";
import { AddIcon } from "@chakra-ui/icons";
import { ShowCardSkeleton } from "../../components/skeletons";
import LibraryUploader from "../../components/podcast/library-uploader";
import { useUser } from "../../context/UserContext";
import { usePodcastLibrary } from "../../context/PodcastLibraryContext";
import { PodcastCard } from "../../components/podcast/podcast-card";
import { Podcast } from "../../models/Podcast";
import { UserCategory } from "../../models/Users";
import GetStartedModal from "../../components/get-started-modal";
import { useApi } from "../../api/braincap-api";
import {usePodcasts} from "../../hooks/usePodcastLibraryQueries";

export default function Podcasts() {
    const user: any = useUser();
    const { data: podcasts = [], isLoading: podcastLoading, error: podcastError } = usePodcasts(user.user);
    const [hasUploadedContent, setHasUploadedContent] = useState(false);
    const [uploadedContentCheck, setUploadedContentCheck] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const youtubeLoginReq = useApi(`/youtube/login`, user.token, false);
    const toast = useToast();

    useEffect(() => {
        if (!user.loading && user.user) {
            if (user.user.category === UserCategory.PUBLISHER) {
                setHasUploadedContent(user.user.has_uploaded_content);
                setUploadedContentCheck(false);
            }
        }
    }, [user.loading, user.user]);

    useEffect(() => {
        if (!youtubeLoginReq.loading && youtubeLoginReq.data) {
            toast({
                title: 'YouTube Login',
                description: 'Redirecting to YouTube for login',
                status: 'success',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed');
                }
            });
            let url = youtubeLoginReq.data.url;

            if (url) {
                window.location.href = url;
            }
        } else if (!youtubeLoginReq.loading && youtubeLoginReq.error) {
            toast({
                title: 'YouTube Login Error',
                description: youtubeLoginReq.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed');
                }
            });
        }
    }, [youtubeLoginReq.loading]);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredPodcasts = podcasts.filter((podcast) => {
        const { title } = podcast;
        const normalizedSearchTerm = searchTerm.toLowerCase();
        return title.toLowerCase().includes(normalizedSearchTerm);
    });

    return (
        <Flex w="full" h={'100%'} flexDirection="column" alignItems={'flex-start'} gap={'32px'}>
            {uploadedContentCheck && (
                <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                    {[...Array(2)].map((_, index) => (
                        <ShowCardSkeleton key={index} />
                    ))}
                </Grid>
            )}
            {!uploadedContentCheck && !hasUploadedContent && <GetStartedModal />}
            {!uploadedContentCheck && hasUploadedContent && (
                <Flex w="full" flexDirection="column" alignItems={'center'} gap={'24px'}>
                    <Flex w="full" flexDirection="row" alignItems={'center'} justifyContent={'space-between'}>
                        <SearchLogo onType={handleSearchInputChange} onOpenWidth={'20%'} />
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Button variant={'ghost'} leftIcon={<AddIcon/>} padding="10px 16px" justifyContent="center" alignItems="center" borderRadius="6px" background="src/pages#FFF" border="1px solid var(--gray-500, #718096)" sx={{gap: "8px"}}>
                                    <Text color="gray.700" fontSize="16px" fontWeight="600" lineHeight="24px">
                                        New
                                    </Text>
                                </Button>
                            </MenuButton>
                            <MenuList>
                                <MenuItem contentEditable={false} onClick={() => {onOpen()}}>
                                    RSS Feed
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                    <Flex w="full" h={'100%'} flexDirection="column" alignItems={'flex-start'} gap={'24px'} style={{overflow: 'auto'}}>
                        {!podcastLoading && hasUploadedContent && podcasts.length > 0 ? (
                            <Grid w={'full'} templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 2fr)']} gap={'24px'}>
                                {filteredPodcasts.map((podcast: Podcast) => (
                                    <PodcastCard key={`${podcast.id}`} podcast={podcast} />
                                ))}
                            </Grid>
                        ) : (
                            <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                                {[...Array(2)].map((_, index) => (
                                    <ShowCardSkeleton key={index} />
                                ))}
                            </Grid>
                        )}
                    </Flex>
                    <LibraryUploader isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                </Flex>
            )}
        </Flex>
    );
}
