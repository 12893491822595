import {Badge} from "@mantine/core";
import {IconBrandYoutube, IconRss} from "@tabler/icons-react";
import React from "react";

export const BraincapRSSBadge = () => {
    return (
        <Badge
            leftSection={<IconRss size={14} />}
            color="orange"
            variant="light"
            size="sm"
            w="fit-content"
        >
            RSS Feed
        </Badge>
    )
}

export const BraincapYoutubeBadge = () => {
    return (
        <Badge
            leftSection={<IconBrandYoutube size={14} />}
            color="red"
            variant="light"
            size="sm"
            w="fit-content"
        >
            YouTube Playlist
        </Badge>
    )
}
