import {Flex, FormControl, FormErrorMessage, Grid, Tag, TagLabel, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {ChakraStylesConfig, Select} from "chakra-react-select";
import {ConfigurationValidation, useLocalization} from "../../context/LocalizationContext";
import {BraincapService, ServiceType, TranscriptionState} from "../../models/Localization";
import {Language, SupportedLanguages} from "../../models/AppConfig";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {GiPlainCircle} from "react-icons/gi";
import {PodcastCartItem} from "../../models/Cart";
import {MediaContentTaskConfig} from "../../models/MediaContent";

export const LanguageConfiguration = ({service, episode, validate}: {
    service: BraincapService,
    episode: PodcastEpisode
    validate: boolean
}) => {
    const [targetLanguages, setTargetLanguages] = useState<Language[]>([])
    const localization: any = useLocalization()
    const [translationLanguages, setTranslationLanguages] = useState<any>({})
    const [dubbingLanguages, setDubbingLanguages] = useState<any>({})
    const [selectedTranslationLanguages, setSelectedTranslationLanguages] = useState<Language[]>([])
    const [selectedDubbingLanguages, setSelectedDubbingLanguages] = useState<Language[]>([])

    useEffect(() => {
        if (episode && !localization.appConfigLoading && localization.appConfigDownloaded) {
            let translation: Language[] = []
            let dubbing: Language[] = []
            let translationMap: any = {}
            let dubbingMap: any = {}
            if ((episode as PodcastEpisode).translation.processing.length > 0) {
                (episode as PodcastEpisode).translation.processing.forEach((language) => {
                    translation.push(language)
                    translationMap[language.code] = language
                })
            }
            if ((episode as PodcastEpisode).translation.completed.length > 0) {
                (episode as PodcastEpisode).translation.completed.forEach((language) => {
                    translation.push(language)
                    translationMap[language.code] = language
                })
            }

            if ((episode as PodcastEpisode).dubbing.processing.length > 0) {
                (episode as PodcastEpisode).dubbing.processing.forEach((language) => {
                    dubbing.push(language)
                    dubbingMap[language.code] = language
                })
            }

            if ((episode as PodcastEpisode).dubbing.completed.length > 0) {
                (episode as PodcastEpisode).dubbing.completed.forEach((language) => {
                    dubbing.push(language)
                    dubbingMap[language.code] = language
                })
            }
            setTranslationLanguages(translationMap)
            setDubbingLanguages(dubbingMap)
            setSelectedTranslationLanguages(translation)
            setSelectedDubbingLanguages(dubbing)
        }
    }, [episode, localization.appConfigLoading, localization.appConfigDownloaded])

    const handleSelect = (type: 'source' | 'target', value: Language | Language[]) => {
        let configs: any = {...localization.episodesConfiguration}
        let validations: any = {...localization.configurationValidation}
        if (type === 'source') {
            let config: Partial<PodcastCartItem> = configs[(episode as PodcastEpisode).id] as Partial<PodcastCartItem>
            let validation: ConfigurationValidation = validations[(episode as PodcastEpisode).id] as ConfigurationValidation
            config = {
                ...config,
                source_language: value as Language
            }
            validation = {
                ...validation,
                source: true
            }
            configs = {
                ...configs,
                [(episode as PodcastEpisode).id]: config
            }
            validations = {
                ...validations,
                [(episode as PodcastEpisode).id]: validation
            }
        } else {
            let config: any = {...localization.episodesConfiguration[(episode as PodcastEpisode).id]};
            let validation: any = {...localization.configurationValidation[(episode as PodcastEpisode).id]};
            configs[(episode as PodcastEpisode).id] = {
                ...config,
                target_languages: value as Language[]
            }
            validations[(episode as PodcastEpisode).id] = {
                ...validation,
                target: true
            }
        }
        localization.setEpisodesConfiguration(configs)
        localization.setConfigurationValidation(validations)
        setTargetLanguages(value as Language[])

    }

    const TargetLanguageSelection = ({languages}: {languages: Language[]}) => {
        const localization: any = useLocalization()
        return (
            <Grid  templateColumns='repeat(4, 3fr)' gap={2}>
                {languages.map((language: Language) => {
                    let price: number = localization.services.get(service.type)?.price as number
                    const isTranslated: boolean = !!translationLanguages[language.code]
                    switch (service.type) {
                        case ServiceType.DUBBING: {
                            if (episode) {
                                price = isTranslated ? price : localization.services.get(ServiceType.TRANSLATION)?.price as number + localization.services.get(ServiceType.DUBBING)?.price as number
                            }
                            //price = dubbingLanguages.has(language.code)? localization.services.get(ServiceType.DUBBING)?.price as number : localization.services.get(ServiceType.DUBBING)?.price as number + localization.services.get(ServiceType.TRANSLATION)?.price as number
                        }
                    }
                        return (
                            <Flex key={language.code} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'center'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                                {service.type === ServiceType.DUBBING && isTranslated && <GiPlainCircle size={'8px'} color={'teal'}/>}
                                {service.type === ServiceType.DUBBING && !isTranslated && <GiPlainCircle size={'8px'} color={'orange'}/>}
                                <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    {language.name}
                                </Text>
                                {service.type === ServiceType.DUBBING &&
                                    <Tag size={'sm'} borderRadius={'12px'} bgColor={'teal.100'} color={'teal.900'} ml={'12px'}>
                                        <TagLabel>${price}/min</TagLabel>
                                    </Tag>
                                }
                            </Flex>
                        )
                    })
                }
            </Grid>
        )
    }

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                LANGUAGES
            </Text>
            {service?.type === ServiceType.DUBBING && !!episode &&
                <Flex direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={'12px'}>
                    <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                        <GiPlainCircle size={'12px'} color={'teal'}/>
                        <Text color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            Translating or Translated
                        </Text>
                    </Flex>
                    <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                        <GiPlainCircle size={'12px'} color={'orange'}/>
                        <Text color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            Not Translated
                        </Text>
                    </Flex>
                </Flex>
            }
            {service?.type === ServiceType.TRANSLATION && !!episode && selectedTranslationLanguages.length > 0?
                <TargetLanguageSelection languages={selectedTranslationLanguages}/>
                :
                null
            }
            {service?.type === ServiceType.DUBBING && !!episode && selectedDubbingLanguages.length > 0?
                <TargetLanguageSelection languages={selectedDubbingLanguages}/>
                :
                null
            }
            {service?.type === ServiceType.DUBBING && !!episode && targetLanguages.length > 0?
                <TargetLanguageSelection languages={targetLanguages}/>
                :
                null
            }
            <Flex w={service?.type === ServiceType.TRANSCRIPTION? '50%' : 'full'} direction={'row'} gap={'24px'} justifyContent={service?.type === ServiceType.TRANSCRIPTION? 'space-around' : 'space-between'}>
                {service?.type === ServiceType.TRANSCRIPTION && <LanguageSelection validate={validate} type={'source'} handleSelection={handleSelect}/>}
                {service?.type === ServiceType.TRANSLATION?
                    <>
                        {!!episode && (episode as PodcastEpisode).transcription.state !== TranscriptionState.NOT_CREATED?
                            <>
                                <LanguageSelection type={'target'} validate={validate} handleSelection={handleSelect} selectedLanguages={translationLanguages} episode={episode as PodcastEpisode}/>
                            </>
                            :
                            <>
                                <LanguageSelection type={'source'} validate={validate} handleSelection={handleSelect}/>
                                <LanguageSelection type={'target'} validate={validate} handleSelection={handleSelect}/>
                            </>
                        }
                    </>
                    :
                    null
                }
                {service?.type === ServiceType.DUBBING?
                    <>
                        {!!episode && (episode as PodcastEpisode).transcription.state !== TranscriptionState.NOT_CREATED?
                            <>
                                <LanguageSelection type={'target'} validate={validate} handleSelection={handleSelect} selectedLanguages={dubbingLanguages} episode={episode as PodcastEpisode}/>
                            </>
                            :
                            <>
                                <LanguageSelection type={'source'} validate={validate} handleSelection={handleSelect}/>
                                <LanguageSelection type={'target'} validate={validate} handleSelection={handleSelect}/>
                            </>
                        }
                    </>
                    :
                    null
                }

            </Flex>
        </Flex>
    )
}

export const MediaContentLanguageConfiguration = ({service, config, setConfig}: {
    service: BraincapService,
    config: Partial<MediaContentTaskConfig>
    setConfig: any
}) => {
    const [targetLanguages, setTargetLanguages] = useState<Language[]>([])
    const localization: any = useLocalization()
    const [translationLanguages, setTranslationLanguages] = useState<any>({})
    const [dubbingLanguages, setDubbingLanguages] = useState<any>({})
    const [selectedTranslationLanguages, setSelectedTranslationLanguages] = useState<Language[]>([])
    const [selectedDubbingLanguages, setSelectedDubbingLanguages] = useState<Language[]>([])

    const handleSelect = (type: 'source' | 'target', value: Language | Language[]) => {
        let currentConfig = {...config}
        if (type === 'source') {
            currentConfig = {
                ...currentConfig,
                source_language: value as Language
            }
        }
        //TODO: Add target language to config after creating translation configuration settings
        setConfig(currentConfig)
        setTargetLanguages(value as Language[])

    }

    const TargetLanguageSelection = ({languages}: {languages: Language[]}) => {
        const localization: any = useLocalization()
        return (
            <Grid  templateColumns='repeat(4, 3fr)' gap={2}>
                {languages.map((language: Language) => {
                    let price: number = localization.services.get(service.type)?.price as number
                    const isTranslated: boolean = !!translationLanguages[language.code]
                    switch (service.type) {
                        case ServiceType.DUBBING: {
                            price = isTranslated ? price : localization.services.get(ServiceType.TRANSLATION)?.price as number + localization.services.get(ServiceType.DUBBING)?.price as number

                            //price = dubbingLanguages.has(language.code)? localization.services.get(ServiceType.DUBBING)?.price as number : localization.services.get(ServiceType.DUBBING)?.price as number + localization.services.get(ServiceType.TRANSLATION)?.price as number
                        }
                    }
                    return (
                        <Flex key={language.code} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'center'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                            {service.type === ServiceType.DUBBING && isTranslated && <GiPlainCircle size={'8px'} color={'teal'}/>}
                            {service.type === ServiceType.DUBBING && !isTranslated && <GiPlainCircle size={'8px'} color={'orange'}/>}
                            <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                {language.name}
                            </Text>
                            {service.type === ServiceType.DUBBING &&
                                <Tag size={'sm'} borderRadius={'12px'} bgColor={'teal.100'} color={'teal.900'} ml={'12px'}>
                                    <TagLabel>${price}/min</TagLabel>
                                </Tag>
                            }
                        </Flex>
                    )
                })
                }
            </Grid>
        )
    }

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                LANGUAGES
            </Text>
            {service?.type === ServiceType.DUBBING &&
                <Flex direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={'12px'}>
                    <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                        <GiPlainCircle size={'12px'} color={'teal'}/>
                        <Text color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            Translating or Translated
                        </Text>
                    </Flex>
                    <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                        <GiPlainCircle size={'12px'} color={'orange'}/>
                        <Text color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            Not Translated
                        </Text>
                    </Flex>
                </Flex>
            }
            {service?.type === ServiceType.TRANSLATION && selectedTranslationLanguages.length > 0?
                <TargetLanguageSelection languages={selectedTranslationLanguages}/>
                :
                null
            }
            {service?.type === ServiceType.DUBBING && selectedDubbingLanguages.length > 0?
                <TargetLanguageSelection languages={selectedDubbingLanguages}/>
                :
                null
            }
            {service?.type === ServiceType.DUBBING && targetLanguages.length > 0?
                <TargetLanguageSelection languages={targetLanguages}/>
                :
                null
            }
            <Flex w={service?.type === ServiceType.TRANSCRIPTION? '50%' : 'full'} direction={'row'} gap={'24px'} justifyContent={service?.type === ServiceType.TRANSCRIPTION? 'space-around' : 'space-between'}>
                {service?.type === ServiceType.TRANSCRIPTION && <LanguageSelection  type={'source'} handleSelection={handleSelect}/>}
                {service?.type === ServiceType.TRANSLATION &&
                    <>
                        <LanguageSelection type={'source'}  handleSelection={handleSelect} />
                        <LanguageSelection type={'target'}  handleSelection={handleSelect}/>
                    </>
                }
                {service?.type === ServiceType.DUBBING &&
                    <>
                        <LanguageSelection type={'source'} handleSelection={handleSelect} />
                        <LanguageSelection type={'target'} handleSelection={handleSelect} />
                    </>
                }
            </Flex>
        </Flex>
    )
}

export const LanguageSelection = ({type, handleSelection, isMultiConfiguration, validate, selectedLanguages, episode}: {
    type: 'source' | 'target',
    handleSelection: (type: 'source' | 'target', value: Language | Language[]) => void,
    isMultiConfiguration?: boolean,
    validate?: boolean,
    selectedLanguages?: any,
    episode?: PodcastEpisode
}) =>
{
    const localization: any = useLocalization()
    const [languages, setLanguages] = useState<SupportedLanguages>()
    const [selectedOptions, setSelectedOptions] = useState<any>()
    const [options, setOptions] = useState<any>()
    const [isInvalid, setIsInvalid] = useState<boolean>(false)
    const isMulti = type === 'target'

    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            setLanguages(localization.supportedLanguages)
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded])

    useEffect(() => {
        if (validate) {
            if (type === 'source') {
                setIsInvalid(!selectedOptions)
            } else {
                setIsInvalid(!selectedOptions)
            }
        } else {
            setIsInvalid(false)
        }
    }, [validate])

    useEffect(() => {
        if (languages) {
            let source: any = []
            if (type === 'target' && !isMultiConfiguration) {
                if (!!selectedLanguages && Object.keys(selectedLanguages).length > 0) {
                    languages[type].forEach((language: Language) => {
                        if (!selectedLanguages[language.code]) {
                            source.push({value: language.code, label: language.name})
                        }
                    })
                    setOptions(source)
                } else {
                    languages[type].forEach((language: Language) => {
                        source.push({value: language.code, label: language.name})
                    })
                    setOptions(source)
                }
            } else {
                languages[type].forEach((language: Language) => {
                    source.push({value: language.code, label: language.name})
                })
                setOptions(source)
            }
            setOptions(source)
        }
    }, [languages])
    const handleSourceChange = (selectedOption: any) => {
        switch (type) {
            case 'source': {
                let selected: Language = {code: selectedOption.value, name: selectedOption.label}
                handleSelection(type, selected)
                setIsInvalid(false)
                break
            }
            case 'target': {
                let selected: Language[] = []
                selectedOption.forEach((option: any) => {
                    selected.push({code: option.value, name: option.label})
                })
                handleSelection(type, selected)
                if (selectedOption.length === 0 && validate) {
                    setIsInvalid(true)
                } else {
                    setIsInvalid(false)
                }
                break
            }
        }
        setSelectedOptions(selectedOption)
    }

    const chakraStyles: ChakraStylesConfig = {
        control: (provided, state) => ({
            ...provided,
            background: 'transparent',
            h: 'full',
            borderColor: 'gray.300',
            border: '1px',
            borderRadius: '12px',
            gap: '12px',
            boxShadow: 'none',  // You may need this to disable the default box-shadow
            '&:hover': {
                background: 'transparent',
                borderColor: 'gray.300',
            },
            '&:focus': {
                background: 'transparent',
                borderColor: 'gray.300',
            }
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            background: 'transparent',
            p: 0,
            w: "40px",
            h: 'full',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            w: 'full',
            h: 'full',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            color: 'gray.900',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
        }),
    };

    return (
        <FormControl isRequired={true} isInvalid={isInvalid}>
            <Select
                isMulti={isMulti}
                size={'md'}
                name="colors"
                chakraStyles={chakraStyles}
                onChange={handleSourceChange}
                options={options}
                value={selectedOptions}
                placeholder={`${type.charAt(0).toUpperCase() + type.slice(1)} language`}
                variant="filled"                aria-errormessage={'error'}
            />
            <FormErrorMessage>Please select a {type} language</FormErrorMessage>
        </FormControl>
    )
}
