import React, { useCallback, useEffect, useState } from 'react';
import {
    Modal,
    Paper,
    Button,
    Text,
    Progress,
    Flex,
    Loader,
    Select,
    Grid,
    Image,
    Group,
    Badge,
    Timeline, Divider, ScrollArea
} from '@mantine/core';
import {IconBrandYoutube, IconCheck, IconRss, IconX} from '@tabler/icons-react';
import Fuse from 'fuse.js';
import { Podcast } from "../../models/Podcast";
import { PodcastEpisode } from "../../models/PodcastEpisode";
import { apiStates, useApi } from "../../api/braincap-api";
import { useUser } from "../../context/UserContext";
import {useBreakpointValue, useToast} from "@chakra-ui/react";
import { EpisodesIcon } from "../../assets/EpisodesIcon";
import { BraincapRSSBadge, BraincapYoutubeBadge } from "../../assets/library-tags";
import {BraincapYoutubeVideoInfo} from "../../models/Youtube";
import {CustomPagination} from "../custom-pagination";
import {useQueryClient} from "@tanstack/react-query";
import {useEpisodes} from "../../hooks/usePodcastLibraryQueries";

interface EpisodeDisplayModalProps {
    opened: boolean;
    close: () => void;
    podcast: Podcast;
}

export const YoutubeEpisodeDisplayModal: React.FC<EpisodeDisplayModalProps> = ({
                                                                                   opened,
                                                                                   close,
                                                                                   podcast,
                                                                               }) => {
    const user: any = useUser();
    const [youtubeVideos, setYoutubeVideos] = useState<BraincapYoutubeVideoInfo[]>([]);
    const [currentStep, setCurrentStep] = useState<{[key: string]: number}>({});
    const toast: any = useToast();
    const [loading, setLoading] = useState<boolean>(true);
    const [matchedEpisodes, setMatchedEpisodes] = useState<{[key: string]: BraincapYoutubeVideoInfo}>({});
    const [episodeMap, setEpisodeMap] = useState<{[key: string]: PodcastEpisode}>({});
    const [nonMappedEpisodes, setNonMappedEpisodes] = useState<PodcastEpisode[]>([]);
    const [activeFilter, setActiveFilter] = useState<string | null>(null);
    const youtubePlaylistsVideoReq = useApi(`/youtube/channel/videos/${podcast.youtube_meta.channelId}`, user.token, false);
    const mapPodcastEpisodeToYoutubeVideoReq = useApi(`/publisher/youtube/episodes/connect/${podcast.id}`, user.token, false);
    const [uploadStatuses, setUploadStatuses] = useState<{[key: string]: 'pending' | 'uploading' | 'success' | 'error'}>({});
    const [mappedEpisodeIds, setMappedEpisodeIds] = useState<string[]>([]);
    const queryClient = useQueryClient();

    const {data: episodeData, isLoading: episodesLoading} = useEpisodes(podcast?.id as string)

    const [page, setPage] = useState(1); // initialize the current page to 1

    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 8);

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;


    useEffect(() => {
        if (opened) {
            youtubePlaylistsVideoReq.execute();
        }
    }, [opened]);

    useEffect(() => {
        if (!episodesLoading && episodeData && episodeData.length > 0) {
            const nonMappedEp = episodeData.filter((episode: any) =>
                !episode.youtube_meta?.is_mapped
            );
            setNonMappedEpisodes(nonMappedEp);
        }
    }, [episodeData, episodesLoading]);

    useEffect(() => {
        if (!youtubePlaylistsVideoReq.loading && youtubePlaylistsVideoReq.state === apiStates.SUCCESS) {
            setYoutubeVideos(youtubePlaylistsVideoReq.data);
            setLoading(false);
        } else if (!youtubePlaylistsVideoReq.loading && youtubePlaylistsVideoReq.error) {
            toast({
                title: 'YouTube Playlist Videos',
                description: 'Error fetching YouTube Playlist Videos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    }, [youtubePlaylistsVideoReq.loading]);

    const handleConfirm = useCallback((episodeId: string) => {
        const matchedVideo = matchedEpisodes[episodeId];
        if (!matchedVideo) {
            console.error('No matched video found for episode:', episodeId);
            return;
        }

        const youtubeVideoId = matchedVideo.videoId;

        // Update upload status to show processing
        setUploadStatuses(prev => ({...prev, [episodeId]: 'uploading'}));

        mapPodcastEpisodeToYoutubeVideoReq.setPayloadData({
            youtubeVideoId: youtubeVideoId,
            episodeId: episodeId,
            podcastId: podcast.id,
            matchedVideo: matchedVideo
        });
    }, [matchedEpisodes, podcast.id, mapPodcastEpisodeToYoutubeVideoReq]);

    useEffect(() => {
        if (!mapPodcastEpisodeToYoutubeVideoReq.loading) {
            if (mapPodcastEpisodeToYoutubeVideoReq.state === apiStates.SUCCESS) {
                // Invalidate episodes query to refresh data
                queryClient.invalidateQueries({queryKey: ['episodes']});

                toast({
                    title: 'Mapping Message',
                    description: 'YouTube video successfully mapped',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
            } else if (mapPodcastEpisodeToYoutubeVideoReq.state === apiStates.ERROR) {
                toast({
                    title: 'Upload Error',
                    description: mapPodcastEpisodeToYoutubeVideoReq.error?.message || 'Failed to process YouTube video',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right'
                });
            }
        }
    }, [
        mapPodcastEpisodeToYoutubeVideoReq.loading,
        mapPodcastEpisodeToYoutubeVideoReq.state,
        queryClient,
        toast
    ]);

    useEffect(() => {
        if (nonMappedEpisodes.length > 0) {
            const newEpisodeMap: { [key: string]: PodcastEpisode } = {};
            nonMappedEpisodes.forEach((episode) => {
                newEpisodeMap[episode.id] = episode;
            });
            setEpisodeMap(newEpisodeMap);
        }
    }, [nonMappedEpisodes]);

    const videoFuse = new Fuse(youtubeVideos, {
        keys: ['title'],
        threshold: 0.5,
        includeScore: true,
    });
    const getMatchingYoutubeVideo = (episodeTitle: string) => {
        const results = videoFuse.search(episodeTitle);
        return results.length > 0 ? results[0].item : null;
    };

    useEffect(() => {
        if (!loading) {
            const newMatchedEpisodes: {[key: string]: BraincapYoutubeVideoInfo} = {};
            nonMappedEpisodes.forEach((episode: PodcastEpisode) => {
                const match = getMatchingYoutubeVideo(episode.title);
                if (match) {
                    newMatchedEpisodes[episode.id] = match;
                }
            });
            setMatchedEpisodes(newMatchedEpisodes);
        }
    }, [loading, nonMappedEpisodes, youtubeVideos]);

    const youtubeVideosSelect = youtubeVideos.map(video => ({
        value: video.videoId,
        label: video.title
    }));
    const handleVideoSelect = (episodeId: string, videoId: string) => {
        const selectedVideo = youtubeVideos.find(video => video.videoId === videoId);
        if (selectedVideo) {
            setMatchedEpisodes(prev => ({...prev, [episodeId]: selectedVideo}));
            setCurrentStep(prev => ({ ...prev, [episodeId]: 1 }));
        }
    };

    const filteredEpisodes = nonMappedEpisodes.filter(episode => {
        if (!activeFilter) return true;
        if (activeFilter === 'Found Match') return !!matchedEpisodes[episode.id];
        if (activeFilter === 'Unmatched') return !matchedEpisodes[episode.id];
        return true;
    });

    const sortedEpisodes = [...filteredEpisodes].sort((a, b) => {
        const aMatched = matchedEpisodes[a.id] ? 1 : 0;
        const bMatched = matchedEpisodes[b.id] ? 1 : 0;
        return bMatched - aMatched;
    });

    const totalEpisodes = nonMappedEpisodes.length;
    const matchedCount = Object.keys(matchedEpisodes).length;
    const unmatchedCount = totalEpisodes - matchedCount;
    const progress = (matchedCount / totalEpisodes) * 100;

    return (
        <Modal
            opened={opened}
            onClose={close}
            size="100%"
            title={`Mapping Dashboard`}
            styles={{
                title: {
                    width: '100%',
                    alignSelf: 'center',
                    fontWeight: 700,
                    fontSize: '1.5rem',
                    justifyContent: 'center',
                },
                body: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 60px)',
                },
            }}
        >
            <Flex direction="column" gap={'md'} style={{ height: '100%', overflow: 'hidden' }}>
                <Flex direction="row" gap="md" mb="md">
                    <Paper shadow="sm" p={'md'} radius="md" withBorder style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Flex align={'flex-start'} gap={'md'} direction={'row'} style={{ height: '100%' }} justify={'space-between'}>
                            <Flex direction="column" gap="xs" w={'100%'}>
                                <BraincapRSSBadge />
                                <Flex direction="column" gap="xs" style={{ flex: '1 0 0' }} justify={'space-between'}>
                                    <Text c="gray.8" fz="md" fw={600} lineClamp={2}>
                                        {podcast.title}
                                    </Text>
                                    <Group gap="xs">
                                        <EpisodesIcon />
                                        <Text c="gray.7" fz="sm">
                                            {podcast.episodes.length} Episodes
                                        </Text>
                                    </Group>
                                </Flex>
                            </Flex>
                            <Divider orientation={'vertical'} />
                            <Flex direction="column" gap="xs" w={'100%'}>
                                <BraincapYoutubeBadge />
                                <Flex direction="column" gap="xs" style={{ flex: '1 0 0' }} justify={'space-between'}>
                                    <Text c="gray.8" fz="md" fw={600} lineClamp={2}>
                                        {podcast.youtube_meta.title}
                                    </Text>
                                    <Group gap="xs">
                                        <EpisodesIcon />
                                        <Text c="gray.7" fz="sm">
                                            {youtubeVideos.length} Episodes
                                        </Text>
                                    </Group>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Paper>
                    <Paper shadow="sm" p="md" radius="md" withBorder style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Text fw={'bold'} size="xl" mb="md">PROGRESS</Text>
                        <Flex direction="column" justify="space-between" style={{ flex: 1 }}>
                            <div>
                                <Flex align="center" gap={'xs'} mb="xs">
                                    <Text fw={500} size="md">{matchedCount} / {totalEpisodes} episodes</Text>
                                </Flex>
                                <Progress value={progress} size="md" radius="xl" />
                            </div>
                            <Group mt="md">
                                <Badge
                                    color={activeFilter === 'Found Match' ? 'blue' : 'gray'}
                                    onClick={() => setActiveFilter(activeFilter === 'Found Match' ? null : 'Found Match')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Found Match: {matchedCount}
                                </Badge>
                                <Badge
                                    color={activeFilter === 'Unmatched' ? 'blue' : 'gray'}
                                    onClick={() => setActiveFilter(activeFilter === 'Unmatched' ? null : 'Unmatched')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Unmatched: {unmatchedCount}
                                </Badge>
                            </Group>
                        </Flex>
                    </Paper>
                </Flex>
                <Flex w={'full'} justify={'center'} align={'center'} direction={'row'} gap={'12px'}>
                    <CustomPagination
                        totalPages={Math.ceil(sortedEpisodes.length / pageSize)}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </Flex>
                {loading ? (
                    <Flex direction="column" gap="md" align="center" style={{ flex: 1 }}>
                        <Loader color="teal" />
                        <Text>Loading YouTube Videos</Text>
                    </Flex>
                ) : (
                    <ScrollArea style={{ flex: 1 }}>
                        <Grid gutter="md">
                            {sortedEpisodes.slice(startIndex, endIndex).map((episode) => {
                                const matchedVideo = matchedEpisodes[episode.id];
                                const step = currentStep[episode.id] || 0;
                                const uploadStatus = uploadStatuses[episode.id];
                                const isMapped = mappedEpisodeIds.includes(episode.id);

                                if (isMapped) return null;

                                return (
                                    <Grid.Col key={episode.id} span={6}>
                                        <Paper p="md" shadow="sm" radius="md" withBorder style={{
                                            height: '100%',
                                            opacity: isMapped ? 0.5 : 1,
                                            pointerEvents: isMapped ? 'none' : 'auto'
                                        }}>
                                            <Timeline active={step} bulletSize={24} lineWidth={2}>
                                                <Timeline.Item bullet={<IconRss size={12} />} title="RSS Feed">
                                                    <Flex direction="column" gap="md">
                                                        <Flex direction="row" align="center" gap="md">
                                                            <Image src={episode.image_url} radius="md" w="72px" h="72px"/>
                                                            <Flex direction="column" justify="space-between" style={{ flex: '1 0 0' }}>
                                                                <BraincapRSSBadge />
                                                                <Text fw={500} fs="md" lineClamp={2}>{episode.title}</Text>
                                                                <Text size="sm" color="dimmed">Duration: {episode.duration}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Timeline.Item>
                                                <Timeline.Item
                                                    bullet={<IconBrandYoutube size={12} />}
                                                    title="YouTube Video"
                                                    lineVariant={step > 0 ? "solid" : "dashed"}
                                                >
                                                    {matchedVideo ? (
                                                        <Flex direction="column" gap="md">
                                                            {!uploadStatus || uploadStatus === 'error' ? (
                                                                <>
                                                                    <Flex direction="row" align="center" gap="md">
                                                                        <Image src={matchedVideo.thumbnails.high.url} radius="md" w="72px" h="72px"/>
                                                                        <Flex direction="column" justify="space-between" style={{ flex: '1 0 0' }}>
                                                                            <BraincapYoutubeBadge />
                                                                            <Text fw={500} lineClamp={2}>{matchedVideo.title}</Text>
                                                                            <Text size="sm" color="dimmed">Duration: N/A</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction="row" gap="xs" align="center">
                                                                        <Badge color='blue' variant='filled'>Matched</Badge>
                                                                        {uploadStatus === 'error' && <Badge color='red' variant='filled'>Error</Badge>}
                                                                    </Flex>
                                                                    {!uploadStatus && (
                                                                        <Flex direction="column" gap="xs">
                                                                            <Button
                                                                                variant="subtle"
                                                                                onClick={() => handleConfirm(episode.id)}
                                                                                disabled={uploadStatus === 'uploading'}
                                                                                leftSection={<IconCheck size={14} />}
                                                                            >
                                                                                Confirm Match
                                                                            </Button>
                                                                            <Select
                                                                                placeholder="Select different YouTube video"
                                                                                data={youtubeVideosSelect}
                                                                                onChange={(videoId) => handleVideoSelect(episode.id, videoId || '')}
                                                                                searchable
                                                                                nothingFoundMessage="No matching videos"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        </Flex>
                                                                    )}
                                                                    {uploadStatus === 'error' && (
                                                                        <Flex direction="column" gap="xs">
                                                                            <Button
                                                                                onClick={() => handleConfirm(episode.id)}
                                                                                color="red"
                                                                                leftSection={<IconX size={14} />}
                                                                            >
                                                                                Retry
                                                                            </Button>
                                                                            <Select
                                                                                placeholder="Select different YouTube video"
                                                                                data={youtubeVideosSelect}
                                                                                onChange={(videoId) => handleVideoSelect(episode.id, videoId || '')}
                                                                                searchable
                                                                                nothingFoundMessage="No matching videos"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        </Flex>
                                                                    )}
                                                                </>
                                                            ) : uploadStatus === 'uploading' ? (
                                                                <Flex align="center" gap="xs">
                                                                    <Loader size="sm" />
                                                                    <Text size="sm">Processing...</Text>
                                                                </Flex>
                                                            ) : (
                                                                <>
                                                                    <Flex direction="row" align="center" gap="md">
                                                                        <Image src={matchedVideo.thumbnails.high.url} radius="md" w="72px" h="72px"/>
                                                                        <Flex direction="column" justify="space-between" style={{ flex: '1 0 0' }}>
                                                                            <BraincapYoutubeBadge />
                                                                            <Text fw={500} lineClamp={2}>{matchedVideo.title}</Text>
                                                                            <Text size="sm" color="dimmed">Duration: N/A</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction="row" gap="xs" align="center">
                                                                        <Badge color='blue' variant='filled'>Matched</Badge>
                                                                        <Badge color='green' variant='filled'>Processed</Badge>
                                                                    </Flex>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <Flex direction="column" gap="md">
                                                            <Text size="sm" c="dimmed">No matching YouTube video found.</Text>
                                                            <Select
                                                                placeholder="Select YouTube video"
                                                                data={youtubeVideosSelect}
                                                                onChange={(videoId) => handleVideoSelect(episode.id, videoId || '')}
                                                                searchable
                                                                nothingFoundMessage="No matching videos"
                                                                style={{ width: '100%' }}
                                                            />
                                                        </Flex>
                                                    )}
                                                </Timeline.Item>
                                            </Timeline>
                                        </Paper>
                                    </Grid.Col>
                                );
                            })}
                        </Grid>
                    </ScrollArea>
                )}
            </Flex>
        </Modal>
    );
};
