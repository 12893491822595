import {AppShell, Badge, Flex} from '@mantine/core';
import React, {useEffect, useMemo, useState} from "react";
import {IconType} from "react-icons";
import {FiHome, FiLogOut, FiShoppingCart} from "react-icons/fi";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import {useDisclosure as useChakraDisclosure} from "@chakra-ui/react";
import {useDisclosure as useMantineDisclosure} from '@mantine/hooks';
import {useLibraryNavigation} from "../context/LibraryNavigationContext";
import {TranscriptionSession} from "../models/Localization";
import PodcastLibrarySidebar from "../sidebars/podcast/podcast-library-sidebar";
import {useCart} from "../hooks/useCartQueries";
import PodcastEpisodeLibrarySidebar from "../sidebars/podcast/podcast-episode-library-sidebar";
import CartSidebar from "../sidebars/cart/cart-sidebar";


interface LinkItemProps {
    name: string;
    icon: IconType;
}

interface Page {
    children: React.ReactNode | React.ReactNode[],
}

const data = [
    { link: '', label: 'Library', icon: FiHome },
    { link: '', label: 'Cart', icon: FiShoppingCart },
    {link: '', label: 'Logout', icon: FiLogOut},
];
export function PublishersShell({children}: Page) {
    const libraryNavigation: any = useLibraryNavigation();
    const [opened, {toggle}] = useMantineDisclosure();
    const [mobileOpened, { toggle: toggleMobile }] = useMantineDisclosure();
    const [desktopOpened, { toggle: toggleDesktop }] = useMantineDisclosure(true);
    const [active, setActive] = useState('Podcasts');
    const auth: any = useAuth(); // Assuming you have a useAuth hook
    const { isOpen: isOpenCart, onOpen: onOpenCart, onClose: onCloseCart } = useChakraDisclosure();
    const navigate = useNavigate();
    const location = useLocation();
    const {sessionId}: any = useParams()
    const [session, setSession] = useState<TranscriptionSession>()
    const [podcast, setPodcast] = useState<any>(null)
    const [asideWidth, setAsideWidth] = useState(0);
    const { data: cart = [], isLoading: cartLoading } = useCart()
    const {episodeId, checkout} = useParams()




    useEffect(() => {
        if (libraryNavigation.studioSession) {
            setSession(libraryNavigation.studioSession)
        } else {
        }
    }, [libraryNavigation.studioSession]);

    useEffect(() => {
        if (libraryNavigation.currentPodcast) {
            setPodcast(libraryNavigation.currentPodcast)
        }
    }, [libraryNavigation.currentPodcast]);


    const links = data.map((item) => {
        const isActive = item.label === active;

        const handleClick = () => {
            switch (item.label) {
                case 'Logout':
                    auth.logout();
                    break;
                case 'Cart':
                    navigate('/cart')
                    break;
                case 'Library':
                    navigate('/');
                    break;
            }
        };

        return (
            <Flex
                key={item.label}
                w={'fit-content'}
                component="a"
                href={item.link}
                align="center"
                onClick={(event) => {
                    event.preventDefault();
                    setActive(item.label);
                    handleClick();
                }}
                style={{
                    textDecoration: 'none',
                    color: isActive ? 'var(--mantine-color-teal-light-color)' : 'var(--mantine-color-gray-7)',
                    padding: '8px',
                    borderRadius: 'var(--mantine-radius-sm)',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s, color 0.3s',
                    position: 'relative',
                }}
                onMouseOver={(e) => {
                    if (!isActive) {
                        e.currentTarget.style.backgroundColor = 'var(--mantine-color-gray-0)';
                        e.currentTarget.style.color = 'var(--mantine-color-black)';
                    }
                }}
                onMouseOut={(e) => {
                    if (!isActive) {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'var(--mantine-color-gray-7)';
                    }
                }}
            >
                <item.icon
                    style={{
                        color: '#1A202C',
                        width: '24px',
                        height: '24px',
                        stroke: '1.5',
                    }}
                />
                {item.label === 'Cart' && cart.length > 0 && (
                    <Badge
                        size="xs"
                        variant="filled"
                        color="red"
                        style={{
                            position: 'absolute',
                            top: -2,
                            right: 0,
                            borderRadius: '50%',
                            padding: '0.25rem',
                            minWidth: 'unset',
                        }}
                    >
                        {cart.length}
                    </Badge>
                )}
            </Flex>
        );
    });

    const isTranscriptionRoute = useMemo(() => {
        const segments = location.pathname.split('/');
        return segments[1] === 'transcription';
    }, [location.pathname]);

    const isLibraryRoute = useMemo(() => {
        const segments = location.pathname.split('/');
        return segments[1] === 'library';
    }, [location.pathname]);



    useEffect(() => {
        if (isTranscriptionRoute) {
            setSession(libraryNavigation.sessions[sessionId])
        }
    }, []);

    useEffect(() => {
        if (isTranscriptionRoute) {
            setAsideWidth(0)
        }
    }, [isTranscriptionRoute, location.pathname]);

    useEffect(() => {
        if (isLibraryRoute && libraryNavigation.displayLibrarySidebar) {
            setAsideWidth(600);
        } else if (isLibraryRoute && episodeId) {
            setAsideWidth(600);
        } else if (isLibraryRoute && episodeId && checkout) {
            setAsideWidth(600)
        }
        else if (isLibraryRoute && !episodeId && !checkout) {
            setAsideWidth(0)
        } else {
            setAsideWidth(0)
        }
    }, [libraryNavigation.displayLibrarySidebar, isLibraryRoute, location.pathname]);

    useEffect(() => {
        if (!isTranscriptionRoute && !isLibraryRoute) {
            setAsideWidth(0)
        }
    }, [location.pathname]);

    return (
        <AppShell
            navbar={{
                width: !isTranscriptionRoute ? { base: 52, sm: 60, lg: 65 } : 0,
                breakpoint: 'sm',
                collapsed: { mobile: !mobileOpened, desktop: !desktopOpened }
            }}
            aside={{
                width: asideWidth,
                breakpoint: 'md',
                collapsed: { desktop: false, mobile: true }
            }}
            padding={'xs'}
            styles={{
                root: {
                    height: '100vh',
                    overflow: 'hidden',
                },
                main: {
                    height: '100%',
                    width: `calc(100% - var(--mantine-navbar-width, 0px))`,
                    marginLeft: 'var(--mantine-navbar-width, 0px)',
                    transition: 'width 200ms ease, margin-left 200ms ease',
                },
                navbar: {
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px',
                    gap: '10px',
                    position: 'fixed',
                    transition: 'width 200ms ease',
                }
            }}
        >
            {!isTranscriptionRoute &&
                <AppShell.Navbar>
                    <Flex direction={'column'} gap={'10px'} justify={'flex-start'} align={'flex-start'}>
                        {links}
                    </Flex>
                </AppShell.Navbar>
            }
            <AppShell.Main bg={'gray.1'}>
                {children}
            </AppShell.Main>
            {(isLibraryRoute && episodeId) &&
                <AppShell.Aside bg={'white'}>
                    <PodcastEpisodeLibrarySidebar/>
                </AppShell.Aside>
            }
            {(isLibraryRoute && episodeId && checkout) &&
                <AppShell.Aside bg={'white'}>
                    <CartSidebar/>
                </AppShell.Aside>
            }
        </AppShell>
    );
}
