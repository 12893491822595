import {Flex, Menu, SegmentedControl, Text} from "@mantine/core";
import {useLibraryNavigation} from "../context/LibraryNavigationContext";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PodcastTranscriptionTaskMeta, TranscriptionState} from "../models/Localization";
import {IconCircleCheck, IconCircleDashedCheck, IconProgressCheck} from "@tabler/icons-react";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {MenuButton, Skeleton} from "@chakra-ui/react";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import ReactPlayer from "react-player";
import TranscriptionTaskSidebar from "../sidebars/localization/transcription-task-sidebar";


export enum TaskState {
    NOT_STARTED = 'Not Started',
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
}

export interface TranscriptionTaskStates {
    AI: TaskState,
    TRANSCRIPTIONIST: TaskState,
    EDITOR: TaskState,
    PUBLISHER_REVIEW: TaskState,
    FINAL: TaskState,
}
export default function TranscriptionStudio() {
    const libraryNavigation: any = useLibraryNavigation()
    const {currentPodcast} = usePodcastLibrary()
    const { sessionId }: any = useParams();
    const [session, setSession] = useState<any>(libraryNavigation.studioSession)
    const [activeTab, setActiveTab] = useState('description');

    const [taskStates, setTaskStates] = useState<any>({
        AI: TaskState.NOT_STARTED,
        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
        EDITOR: TaskState.NOT_STARTED,
        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
        FINAL: TaskState.NOT_STARTED
    })

    useEffect(() => {
        if (session) {
            setSession(libraryNavigation.studioSession)
            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    setTaskStates({
                        AI: TaskState.IN_PROGRESS,
                        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.COMPLETED,
                        FINAL: TaskState.IN_PROGRESS
                    })
                    break
                }
            }
        }
    }, []);

    const StateIcon = ({state}: {state: TaskState}) => {
       return (
           <>
               {state === TaskState.NOT_STARTED && <IconCircleDashedCheck stroke={2} style={{color: 'gray'}}/>}
                {state === TaskState.IN_PROGRESS && <IconProgressCheck stroke={2} style={{color: 'orange'}}/>}
                {state === TaskState.COMPLETED && <IconCircleCheck stroke={2} style={{color: 'teal'}}/>}
           </>
       )
    }

    return (
        <Flex direction="column" gap="20px" align="flex-start" justify="center" style={{width: '100%'}}>
            <Flex w={'100%'} align={'center'} justify={'space-between'} direction="row">
                <Flex direction="row" align="flex-start" justify="flex-start">
                    <ChevronLeftIcon boxSize={'24px'} onClick={() => {
                        window.history.back()
                    }}/>
                </Flex>
                <Text w={'full'} fw={500} lh={'24px'} fs={'16px'} c={'#2D3748'}>{session.episode.title}</Text>
                <Flex  direction="row" align="flex-start" justify="flex-start">
                </Flex>
            </Flex>
            <Flex w={'100%'} h={'100%'} direction={'row'} gap={'12px'} align={'flex-start'} justify={'space-between'}>
                <Flex w={'100%'} h={'100%'} direction={'column'} gap={'12px'} align={'flex-start'}>
                    <Flex w={'100%'} direction="column" align="flex-start" justify="flex-start">
                        {!session && <Skeleton w={'full'} h={'full'} />}
                        {!!session && <>
                            <ReactPlayer
                                width={'100%'}
                                height={'450px'}
                                controls={true}
                                url={session?.youtube_url}
                                style={{borderRadius: '12px'}}
                            />
                        </>
                        }
                    </Flex>
                    <Flex
                        w={'100%'}
                        h={'100%'}
                        direction="column"
                        align="flex-start"
                        justify="flex-start"
                        style={{
                            padding: '12px',
                            gap: '12px',
                            background: 'white',
                        }}
                    >
                        <Flex w={'100%'} direction="column" justify={'flex-start'} align="flex-start" gap="md" style={{ alignSelf: 'stretch' }}>
                            <SegmentedControl
                                value={activeTab}
                                variant="outline"
                                color={'#319795'}
                                size={'sm'}
                                onChange={setActiveTab}
                                data={[
                                    { label: 'Description', value: 'description' },
                                ]}
                                styles={{
                                    root: {
                                        shadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                    },
                                    label: {
                                        fontWeight: 600,
                                        fontSize: '14px',
                                    }
                                }}
                            />
                        </Flex>
                        <Flex
                            w={'100%'}
                            h={'100vh'}
                            flex={1} // Changed from h={'100%'} to flex={1}
                            direction="column"
                            align="flex-start"
                            style={{
                                whiteSpace: 'pre-wrap',
                                maxHeight: 'calc(100vh - 600px)' // Adjust this value based on your needs
                            }}
                        >
                            {(activeTab === 'description') && <Text style={{
                                color: '#171923',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '24px',
                                fontFamily: 'sans-serif',
                                fontStyle: 'normal',
                                overflowY: 'auto',
                            }}>
                                {(session as PodcastTranscriptionTaskMeta).episode.description}
                            </Text>
                            }

                        </Flex>
                    </Flex>
                </Flex>
                <TranscriptionTaskSidebar/>
            </Flex>

        </Flex>
    )
}
