import {useUser} from "../../context/UserContext";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Card, Flex, Image, Tabs, Text} from "@mantine/core";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useEpisodes, useRemoveTagFromEpisode} from "../../hooks/usePodcastLibraryQueries";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {ConfigurationModule} from "../../components/episode/configuration-module";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {useGetTranscriptionSessions} from "../../hooks/useLocalizationQueries";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {useDisclosure} from "@mantine/hooks";
import {useToast} from "@chakra-ui/react";
import {secondsToMinutes} from "../../utils/text-utils";
import {ProofreadTranscriptProcessor} from "../../models/Transcription";
import {useLocalization} from "../../context/LocalizationContext";


export default function PodcastEpisodeLibrarySidebar () {
    const user: any = useUser()
    const localization: any = useLocalization()
    const libraryNavigation: any = useLibraryNavigation()
    const {episodeId, podcastId} = useParams()
    const navigate = useNavigate()
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId as string);
    const [episode, setEpisode] = React.useState<PodcastEpisode>();
    const [activeTab, setActiveTab] = useState('overview');
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId as string)
    const [session, setSession] = useState<TranscriptionSession | null>(null);
    const [opened, { close, open }] = useDisclosure(false);
    const [tags, setTags] = useState<string[]>([]);
    const toast = useToast()
    const [navigationButtonText, setNavigationButtonText] = useState<string>('')
    const { mutate: removeTagFromEpisodeReq, isSuccess: isRemoveTagFromEpisodeSuccess,
        isError: isRemoveTagToEpisodeError, status: removeTagFromEpisodesStatus
    } = useRemoveTagFromEpisode();

    const options = {
        apiKey: "public_12a1zBMFJNhXjLF6NP3CJSyuot6p", // This is your API key.
        maxFileCount: 1,
        showFinishButton: true, // Note: You must use 'onUpdate' if you set 'showFinishButton: false' (default).
        styles: {
            colors: {
                primary: "#377dff"
            }
        },
        mimeTypes: [
            "image/jpeg"
        ],
    };

    useEffect(() => {
        if (!episodesLoading && podcastEpisodesData && podcastEpisodesData.length > 0) {
            localization.resetConfiguration()
            const episode = podcastEpisodesData.find((ep: any) => ep.id === episodeId);

            let epTags: any[] = episode?.tags || [];
            if (episode?.transcription.processor) {
                if (epTags.length > 0) {
                    epTags.push(episode.transcription.processor)
                } else {
                    epTags.push(episode.transcription.processor)
                }
            }
            setEpisode(episode);
            setTags(epTags);
        }
    }, [episodesLoading, podcastEpisodesData, episodeId]);


    useEffect(() => {
        if (episode && episode.transcription.state !== TranscriptionState.NOT_CREATED && episode.id  && episode.id === episodeId) {
            if (!isLoadingTranscriptionSession && transcriptionSessions.length > 0) {
                const session: any = transcriptionSessions.find((session: any) => session.episode.id === episodeId);
                setSession(session);
            } else {
            }
        }
    }, [isLoadingTranscriptionSession, transcriptionSessions, episodeId, episode]);

    useEffect(() => {
        if (session) {
            console.log('session', session)
            const navText: any = getNavigationText(session)
            setNavigationButtonText(navText)
        }
    }, [session, episodeId]);

    useEffect(() => {
        if (activeTab === 'configuration') {
            libraryNavigation.setDisplaySidebar(true)
        } else {
            libraryNavigation.setDisplaySidebar(false)
        }
    }, [activeTab]);

    useEffect(() => {
        if (isRemoveTagFromEpisodeSuccess) {
            toast({
                title: 'Tag removed',
                description: 'Tag removed from episode',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

            close()
        }

        if (isRemoveTagToEpisodeError) {
            toast({
                title: 'Error',
                description: 'Error removing tag from episode',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [isRemoveTagFromEpisodeSuccess, isRemoveTagToEpisodeError]);

    const handleRemoveTag = (tag: string) => {
        removeTagFromEpisodeReq({
            episodeId: episodeId as string,
            tag: tag
        })
    }

    const getNavigationText = (sessionData: any) => {
        switch (sessionData.state) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                break
            }
            case TranscriptionState.PROOFREADING: {
                return 'Open AI Transcript'
            }
            case TranscriptionState.PROOFREADING_COMPLETED: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.READY_FOR_PROOFREADING: {
                return 'Open AI Transcript'

            }
            case TranscriptionState.EDITOR_REVIEWING: {
                return 'Open Proofread Transcript'
            }
            case TranscriptionState.EDITOR_REVIEW: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                return 'Start Final Review'
            }
            case TranscriptionState.PUBLISHER_REVIEWING: {
                return 'Resume Final Review'
            }
            case TranscriptionState.COMPLETED: {
                return 'Open Final Transcript'
            }

        }
    }

    const navigateToStudio = () => {
        if (session) {
            libraryNavigation.setStudioSession(session)

            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    navigate(`/transcription/${session.id}`)
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    navigate(`/transcription/${session.id}`)
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.COMPLETED: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
            }
        }
    }

    return (
        <Flex w={'100%'} h={'100%'} direction="column" style={{ alignSelf: 'stretch' }}>
            <Flex w="100%" direction="row" align="center" justify="flex-start" gap="md">
                <ChevronLeftIcon w="24px" h="24px" onClick={() => navigate(`/library/${podcastId}`)} cursor="pointer"/>
            </Flex>
            {episode &&
                <Tabs defaultValue="configuration" style={{ display: 'flex', flexDirection: 'column', height: '100%' }} styles={{
                    root: {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    },
                    panel: {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'auto'
                    }
                }}>
                    <Tabs.List justify="center">
                        <Tabs.Tab value="configuration">Services</Tabs.Tab>
                        <Tabs.Tab value="overview">Overview</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="configuration">
                        <ConfigurationModule/>
                    </Tabs.Panel>
                    <Tabs.Panel value="overview">
                        <Flex w={'100%'} direction="column" style={{ height: '100%' }}>
                            <Flex p={'md'} gap={'16px'} direction="column" style={{ flex: 1, overflowY: 'auto' }}>
                                <Flex align={'flex-start'} gap={'24px'} direction={'row'}>
                                    <Image src={episode.image_url || episode.podcast?.image_url} radius="md" w={'142px'} h={'142px'} />

                                    <Flex w={'100%'} direction="column" justify={'center'} gap="12px" style={{ flex: '1 0 0', alignItems: 'flex-start'}}>
                                        <Text fw={500} lh={'24px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '16px'}} c={'#2D3748'}>
                                            {episode.title}
                                        </Text>
                                        <Text fw={400} lh={'24px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '16px'}} c={'#171923'}>
                                            {secondsToMinutes(episode.duration)}
                                        </Text>
                                        <Text fw={400} lh={'20px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '14px'}} c={'#171923'}>
                                            {new Date(episode.pubDate).toLocaleDateString()}
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Card w="100%" withBorder radius="md" style={{alignItems: 'flex-start', gap: '16px', maxHeight: '20%', whiteSpace: 'pre-wrap'}}>
                                    <Text fw={600} lh={'24px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '16px'}} c={'#1A202C'}>
                                        Description
                                    </Text>
                                    <Text fw={400} lh={'20px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '14px', overflow: "auto"}} c={'#707885'}>
                                        {episode.description}
                                    </Text>
                                </Card>
                            </Flex>
                            {session &&
                                <Flex w={'100%'} p={'md'} justify={'center'} mb="md">
                                    <Button variant={'outline'} w={'100%'} onClick={navigateToStudio}>
                                        {navigationButtonText}
                                    </Button>
                                </Flex>
                            }
                        </Flex>
                    </Tabs.Panel>
                </Tabs>
            }
        </Flex>
    );
}
