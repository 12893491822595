import {LocalizationService} from "../services/localization-service";
import {useQuery} from "@tanstack/react-query";
import {TaskState, TranscriptionTaskStates} from "../studio/transcription-studio";
import {TranscriptMode} from "../models/Transcript";
import {TranscriptionState} from "../models/Localization";

export const useAppConfig = () => {
    return useQuery({
        queryKey: ['appConfig'],
        queryFn: LocalizationService.getAppConfig,
        enabled: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
};

export const useGetTranscriptionSessions = (podcastId: string) => {
    return useQuery({
        queryKey: ['transcriptionSessions', podcastId],
        queryFn: () => LocalizationService.getTranscriptionSessions(podcastId),
        enabled: !!podcastId && podcastId !== '',
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
}

export const useGetAsrTranscript = (session: any, transcriptionStates: TranscriptionTaskStates, mode: TranscriptMode, shouldFetch: boolean) => {
    return useQuery({
        queryKey: ['asrTranscript', session.id],
        queryFn: () => LocalizationService.getAsrTranscript(session.id),
        enabled: shouldFetch && !!session?.id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity
    });
}

export const useGetProofreadTranscript = (session: any, transcriptionStates: TranscriptionTaskStates, mode: TranscriptMode, shouldFetch: boolean) => {
    return useQuery({
        queryKey: ['proofreadTranscript', session.id],
        queryFn: () => LocalizationService.getProofreadTranscript(session.id),
        enabled: shouldFetch && !!session?.id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity
    });
}

export const useGetFinalTranscript = (session: any, transcriptionStates: TranscriptionTaskStates, mode: TranscriptMode, shouldFetch: boolean) => {
    return useQuery({
        queryKey: ['finalTranscript', session.id],
        queryFn: () => LocalizationService.getFinalTranscript(session.id),
        enabled: shouldFetch && !!session?.id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity
    });
}
