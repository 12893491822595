import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Badge,
    Button, Card,
    Flex,
    Grid, Image,
    Menu,
    MultiSelect,
    Pill,
    PillsInput,
    Popover,
    SegmentedControl, Tabs,
    TagsInput,
    Text
} from '@mantine/core';
import {useDisclosure} from "@mantine/hooks";
import {Spinner, useBreakpointValue, useToast} from "@chakra-ui/react";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {SearchLogo} from "../../assets/SearchIcon";
import {SimpleEpisodeCard} from "./episode-card";
import {ShowCardSkeleton} from "../skeletons";
import {useAddTagToEpisodes, useEpisodes} from "../../hooks/usePodcastLibraryQueries";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {RssLocalizationProgress} from "../progress-components";
import {AddIcon, ChevronLeftIcon, EditIcon} from "@chakra-ui/icons";
import {useGetTranscriptionSessions} from "../../hooks/useLocalizationQueries";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import {useNavigate} from "react-router-dom";
import {PodcastPublishedModule} from "../../production/podcast-published-module";
import PodcastChapters from "../../studio/transcript-chapters-configuration";
import PodcastProductionModule from "../../production/podcast-production-module";
import {secondsToMinutes} from "../../utils/text-utils";
import {ConfigurationModule} from "./configuration-module";
import {Podcast} from "../../models/Podcast";


export enum LocalizationProgressFilter {
    ALL = 'All',
    DURATION = 'Duration',
    DATE = 'Date',
    TAGS = 'Tags',
    TRANSCRIPTION_COMPLETED = 'Transcription Completed',
    TRANSCRIPTION_IN_PROGRESS = 'Transcription In Progress',
    TRANSCRIPTION_NOT_STARTED = 'Transcription Not Started',
    TRANSLATION_COMPLETED = 'Translation Completed',
    TRANSLATION_IN_PROGRESS = 'Translation In Progress',
    TRANSLATION_NOT_STARTED = 'Translation Not Started',
    DUBBING_COMPLETED = 'Dubbing Completed',
    DUBBING_IN_PROGRESS = 'Dubbing In Progress',
    DUBBING_NOT_STARTED = 'Dubbing Not Started',
}

interface EpisodeModuleProps {
    podcastId: string;
    podcast: Podcast
}

export const EpisodeModule: React.FC<EpisodeModuleProps> = React.memo(({ podcastId, podcast }) => {
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId);
    const [progressFilter, setProgressFilter] = useState<LocalizationProgressFilter>(LocalizationProgressFilter.ALL);
    const [opened, { close, open }] = useDisclosure(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tags, setTags] = useState<string[]>(['Test']);
    const [selectedTag, setSelectedTag] = useState<string | null>(null);
    const [episodesTags, setEpisodesTags] = useState<any>({});
    const [newTag, setNewTag] = useState('');
    const [selectedEpisodes, setSelectedEpisodes] = useState<string[]>([]);
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId)
    const { mutate: addTagToEpisodesReq, isSuccess: isAddTagToEpisodesSuccess,
        isError: isAddTagToEpisodesError, status: addTagToEpisodesStatus
    } = useAddTagToEpisodes();
    const toast: any = useToast()
    const {setLocalizationTasks} = usePodcastLibrary()
    const [sessionsMap, setSessionsMap] = useState<any>({})
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('published');
    const [nonSelectedEpisodes, setNonSelectedEpisodes] = useState<any>([])

    const dynamicPageSize = useBreakpointValue({ base: 2, md: 4, lg: 6, xl: 6 }) || 6;

    const episodes = useMemo(() => podcastEpisodesData || [], [podcastEpisodesData]);
    const sessions = useMemo(() => transcriptionSessions || [], [transcriptionSessions])


    useEffect(() => {
        if (episodes.length > 0) {
            let tagsData: any = {}

            for (const episode of episodes) {
                if (episode.tags) {
                    for (const tag of episode.tags) {
                        if (!tagsData[tag]) {
                            tagsData[tag] = 1;
                        } else {
                            tagsData[tag] += 1;
                        }
                    }
                }
            }
            setEpisodesTags(tagsData);
        }
    }, [episodes]);

    useEffect(() => {
        if (isAddTagToEpisodesSuccess) {
            toast({
                title: 'Episode Tags Added',
                description: 'Tags added successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setSelectedEpisodes([])
            setNewTag('');
            close();
        }

        if (isAddTagToEpisodesError) {
            toast({
                title: 'Error Adding Tags',
                description: 'There was an error adding tags to the episodes.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [isAddTagToEpisodesSuccess, isAddTagToEpisodesError]);

    useEffect(() => {
        if (sessions.length > 0 ) {
            let tasks: any[] = []
            let sMap: any = {}
            sessions.forEach((session: TranscriptionSession) => {
                sMap[session.id] = session
                switch (session.state) {
                    case TranscriptionState.PROOFREADING_COMPLETED: {
                        tasks.push(session)
                        break;
                    }
                    case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                        tasks.push(session.id)
                        break;
                    }
                }})
            setLocalizationTasks(tasks)
            setSessionsMap(sMap)
        }
    }, [sessions]);

    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleAddNewTag = () => {
        const tagData = !selectedTag? newTag : selectedTag
        console.log(tagData)
        console.log(selectedEpisodes)
        if (tagData) {
            if (selectedTag) {
                addTagToEpisodesReq({episodes: selectedEpisodes, tag: tagData});
            } else if (!selectedTag && !episodesTags[tagData]) {
                addTagToEpisodesReq({episodes: selectedEpisodes, tag: tagData});
            }
        }
    }

    useEffect(() => {
        if (selectedTag) {
            let nSelectedEpisodes: any[] = []
            episodes.forEach((episode) => {
                const episodeTags = episode.tags? episode.tags : []
                if (!episodeTags.includes(selectedTag)) {
                    nSelectedEpisodes.push({ value: episode.id, label: episode.title })
                }
            })
            setNonSelectedEpisodes(nSelectedEpisodes)
        } else {
            setNonSelectedEpisodes([])
        }
    }, [selectedTag])

    const filteredEpisodes = useMemo(() => {
        return episodes.filter((episode) => {
            const { title, tags: episodeTags = [] } = episode;
            const normalizedSearchTerm = searchTerm.toLowerCase();
            const matchesSearch = title.toLowerCase().includes(normalizedSearchTerm);
            const matchesTag = !selectedTag || episodeTags.includes(selectedTag);

            let matchesProgressFilter = true;

            switch (progressFilter) {
                case LocalizationProgressFilter.ALL: {
                    return title.toLowerCase().includes(normalizedSearchTerm);
                }

                case LocalizationProgressFilter.TAGS: {
                    if (selectedTag) {
                        return episodeTags.includes(selectedTag);
                    }
                    break;
                }

                case LocalizationProgressFilter.TRANSCRIPTION_IN_PROGRESS: {
                    return episode.transcription.state !== TranscriptionState.NOT_CREATED && episode.transcription.state !== TranscriptionState.COMPLETED

                }
                case LocalizationProgressFilter.TRANSCRIPTION_COMPLETED:
                    return episode.transcription.state === TranscriptionState.COMPLETED;
                case LocalizationProgressFilter.TRANSCRIPTION_NOT_STARTED: {
                    return episode.transcription.state === TranscriptionState.NOT_CREATED;
                }
                case LocalizationProgressFilter.TRANSLATION_IN_PROGRESS: {
                    return episode.translation.processing.length > 0;
                }
                case LocalizationProgressFilter.TRANSLATION_COMPLETED: {
                    return episode.translation.processing.length === 0;
                }
                case LocalizationProgressFilter.TRANSLATION_NOT_STARTED: {
                    return episode.translation.processing.length === 0 && episode.translation.completed.length === 0;
                }

                case LocalizationProgressFilter.DUBBING_IN_PROGRESS: {
                    return episode.dubbing.processing.length > 0;
                }
                case LocalizationProgressFilter.DUBBING_COMPLETED: {
                    return episode.dubbing.processing.length === 0;
                }
                case LocalizationProgressFilter.DUBBING_NOT_STARTED: {
                    return episode.dubbing.processing.length === 0 && episode.dubbing.completed.length === 0;
                }
            }
        });
    }, [episodes, searchTerm, selectedTag, progressFilter]);

    if (episodesLoading || isLoadingTranscriptionSession) {
        return (
            <Grid w="100%" gutter="24px">
                {[...Array(dynamicPageSize)].map((_, index) => (
                    <Grid.Col span={6} key={index}>
                        <ShowCardSkeleton />
                    </Grid.Col>
                ))}
            </Grid>
        );
    }

    if (episodeError) {
        return <Text>Error loading episodes: {episodeError.message}</Text>;
    }

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} align={'center'} gap={'12px'}>
            <Flex w="100%" direction="row" align="center" justify="flex-start" gap="md" >
                <ChevronLeftIcon w="24px" h="24px" onClick={() => navigate('/')} cursor="pointer"/>
            </Flex>
            <RssLocalizationProgress setProgressFilter={setProgressFilter} podcast={podcast}/>
            <Flex
                direction="column"
                align="flex-start"
                justify={'flex-start'}
                gap="md"
                p="12px"
                w="100%"
                h={'100%'}
                bg="white"
                style={{ borderRadius: '12px' }}
            >
                <Tabs variant={'pills'} defaultValue="published" styles={{
                    root: {
                        width: '100%',
                        height: '100%',
                        overflowY: 'auto',
                        overflow: 'auto',
                        padding: '12px',
                        backgroundColor: 'white',

                    },
                    tabLabel: {
                        fontWeight: 500,
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontFamily: 'sans-serif',
                    },
                    panel: {
                        width: '100%',
                        height: '100%',
                        overflowY: 'auto',
                        overflow: 'auto'
                    }
                }}>
                    <Tabs.List justify="flex-start" w={'fit-content'}>
                        <Tabs.Tab value="published">
                            Published
                        </Tabs.Tab>
                        <Tabs.Tab value="production">
                            Production
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="published">
                        <PodcastPublishedModule episodes={filteredEpisodes} podcast={podcast} />
                    </Tabs.Panel>

                    <Tabs.Panel value="production">
                        <PodcastProductionModule />
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </Flex>
    );
});
