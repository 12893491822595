import {
    Button,
    CloseButton,
    Flex, FormControl, FormErrorMessage, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Spinner,
    Text,
    useToast
} from "@chakra-ui/react";
import {InfoIcon} from "@chakra-ui/icons";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useAuth} from "../../context/AuthContext";
import {useUser} from "../../context/UserContext";
import React, {useEffect, useState} from "react";

export default function LibraryUploader({isOpen, onOpen, onClose}: {isOpen: boolean, onOpen: any, onClose: any}) {
    const auth: any = useAuth()
    const user: any = useUser()
    const [loading, setLoading] = useState(false);
    const [rssFeed, setRssFeed] = useState('')
    const [youtubeChannelLink, setYoutubeChannelLink] = useState('')
    const userDataReq = useApi(ENDPOINTS.PUBLISHER_PARSE_RSS, auth.token, false)
    const toast = useToast()
    //https://rss.art19.com/stanford-gsb
    const handleRssParsing = () => {
        if (!!rssFeed && rssFeed.length > 0) {
            setLoading(true)
            userDataReq.setPayloadData({rss_feed: rssFeed, user: user.user})        }
    }

    useEffect(() => {
        if (!userDataReq.loading && userDataReq.data) {
            user.setUser({...user.user, has_uploaded_content: true})
            //library.setDownloadPodcast(true)
            setLoading(false)
            window.location.href = '/library'
        }

        if (!userDataReq.loading && userDataReq.error) {
            if (userDataReq.error.data.code === 'RSS_FEED_ALREADY_IN_SYSTEM') {
                toast({
                    title: 'Error',
                    description: 'RSS Feed already in system. Please try another one or contact support.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
            setLoading(false)
        }
    }, [userDataReq.loading])

    const RssFeedInput = () => {
        const handleRssFeedChange = (e: any) => {
            setRssFeed(e.target.value)
        }

        return (
            <Flex w={'full'} direction="column" alignItems="flex-start" gap={3}>
                <Text color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">
                    Podcast - Enter RSS Feed
                </Text>
                <FormControl id="rss-feed">
                    <Input value={rssFeed} onChange={handleRssFeedChange} p="8px 16px" borderRadius="6px" borderColor="gray.200" bg="white"/>
                </FormControl>
            </Flex>
        )
    }

    const YoutubeChannelInput = () => {
        const handleChannelLinkChange = (e: any) => {
            setRssFeed(e.target.value)
        }

        return (
            <Flex w={'full'} direction="column" alignItems="flex-start" gap={3}>
                <Text color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">
                    Youtube - Fetch Channel
                </Text>
                <Input value={youtubeChannelLink} onChange={handleChannelLinkChange} p="8px 16px" borderRadius="6px" borderColor="gray.200" bg="white"/>
            </Flex>
        )
    }
    return (
        <Modal size={'lg'} blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p="20px" flexDirection="column" alignItems={'center'} gap="24px" borderRadius="12px" bg="#FFF" boxShadow="0px 4px 14px 0px rgba(0, 0, 0, 0.10)">
                <Flex w={'full'} display="flex" alignItems="center" alignSelf="stretch" justifyContent={'space-between'}>
                    <Flex display="flex" alignItems="center" gap="16px">
                        <Text color="gray.900" fontFamily={'sans-serif'} fontSize="20px" fontWeight={500} lineHeight="28px">
                            Upload Library
                        </Text>
                        <InfoIcon color="gray.900" boxSize={'20px'}/>
                    </Flex>
                    <CloseButton onClick={onClose} />
                </Flex>
                <RssFeedInput/>
                <Button onClick={handleRssParsing} padding="10px 16px" justifyContent="center" alignItems="center" alignSelf="stretch" borderRadius="6px" size="md" variant="solid" isDisabled={rssFeed === '' || loading}>
                    {loading ? <Spinner size={'md'} color={'white'}/> : 'Fetch'}
                </Button>
            </ModalContent>
        </Modal>
    )
}
