import React, {useEffect, useMemo, useState} from 'react';
import {ActionIcon, Avatar, Badge, Flex, Group, Table, Text, Tooltip} from '@mantine/core';
import {PodcastEpisode} from '../models/PodcastEpisode';
import {
    PodcastTranscriptionTask,
    PodcastTranscriptionTaskMeta,
    TranscriptionSession,
    TranscriptionState
} from "../models/Localization";
import {secondsToMinutes} from "../utils/text-utils";
import {IconChevronDown, IconChevronUp, IconSelector} from '@tabler/icons-react';
import {useNavigate, useParams} from "react-router-dom";
import {PodcastStudioEmptyLibraryMessageModal} from "./components/podcast-empty-library-message-modal";
import TranscriptionFilter from "./filters/transcription-filters";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import {apiStates, ENDPOINTS, useApi} from "../api/braincap-api";
import {Spinner, useToast} from "@chakra-ui/react";
import {useUser} from "../context/UserContext";
import { Popover, Button } from '@mantine/core';
import {useDisclosure} from "@mantine/hooks";
import {useGetTranscriptionSessions} from "../hooks/useLocalizationQueries";
import {useQueryClient} from "@tanstack/react-query";
import {YouTubeCaptionStatusFilter} from "./filters/youtube-caption-filters";
import {Podcast} from "../models/Podcast";
import {useCart} from "../hooks/useCartQueries";


type SortDirection = 'asc' | 'desc' | null;
type SortField = 'duration' | 'pubDate' | null;

interface ThProps {
    children: React.ReactNode;
    sortField?: SortField;
    currentSort: SortField;
    direction: SortDirection;
    onSort?: () => void;
}

function Th({ children, sortField, currentSort, direction, onSort }: ThProps) {
    const Icon = direction === 'asc' ? IconChevronUp :
        direction === 'desc' ? IconChevronDown :
            IconSelector;

    return (
        <Table.Th>
            <Flex align="center" gap="xs">
                <Text size="sm">{children}</Text>
                {sortField && (
                    <ActionIcon
                        size="sm"
                        variant="subtle"
                        onClick={onSort}
                        color={currentSort === sortField ? 'blue' : 'gray'}
                    >
                        <Icon size={14} stroke={1.5} />
                    </ActionIcon>
                )}
            </Flex>
        </Table.Th>
    );
}

const getTranscriptionStateDisplay = (state: TranscriptionState) => {
    switch (state) {
        case TranscriptionState.NOT_CREATED:
            return { label: 'Not Started', color: 'gray' };
        case TranscriptionState.CREATED:
            return { label: 'Created', color: 'blue' };
        case TranscriptionState.SPEECH_TO_TEXT:
            return { label: 'Processing', color: 'yellow' };
        case TranscriptionState.SPEECH_TO_TEXT_RUNNING:
            return { label: 'Running', color: 'orange' };
        case TranscriptionState.STORAGE_SAVING_ERROR:
            return { label: 'Storage Saving Error', color: 'red' };
        case TranscriptionState.ASSEMBLY_AI_TRANSCRIPTION_ERROR:
            return { label: 'Assembly AI Transcription Error', color: 'red' };
        case TranscriptionState.ASSEMBLY_AI_FETCHING_ERROR:
            return { label: 'Assembly AI Fetching Error', color: 'red' };
        case TranscriptionState.SPEECH_TO_TEXT_CREATION_ERROR:
            return { label: 'Speech to Text Creation Error', color: 'red' };
        case TranscriptionState.SPEECH_TO_TEXT_ERROR:
            return { label: 'Speech to Text Error', color: 'red' };
        case TranscriptionState.READY_FOR_PROOFREADING:
            return { label: 'Ready for Proofreading', color: 'green' };
        case TranscriptionState.READY_FOR_PROOFREADING_ERROR:
            return { label: 'Ready for Proofreading Error', color: 'red' };
        case TranscriptionState.PROOFREADING:
            return { label: 'Proofreading', color: 'blue' };
        case TranscriptionState.PROOFREADING_COMPLETED:
            return { label: 'Proofreading Completed', color: 'green' };
        case TranscriptionState.BLOCKED:
            return { label: 'Blocked', color: 'red' };
        case TranscriptionState.CANCELED:
            return { label: 'Canceled', color: 'gray' };
        case TranscriptionState.EDITOR_REVIEW:
            return { label: 'Editor Review', color: 'blue' };
        case TranscriptionState.EDITOR_REVIEWING:
            return { label: 'Editor Reviewing', color: 'blue' };
        case TranscriptionState.PUBLISHER_EDITING:
            return { label: 'Publisher Editing', color: 'blue' };
        case TranscriptionState.PUBLISHER_REVIEWING:
            return { label: 'Publisher Reviewing', color: 'blue' };
        case TranscriptionState.EDITOR_REVIEW_COMPLETED:
            return { label: 'Editor Review Completed', color: 'green' };
        case TranscriptionState.COMPLETED:
            return { label: 'Completed', color: 'green' };
        default:
            return { label: 'Unknown', color: 'gray' };
    }
};

export const PodcastPublishedModule = ({ episodes, podcast }: { episodes: PodcastEpisode[], podcast: Podcast }) => {
    const user: any = useUser()
    const library: any = usePodcastLibrary()
    const [sortField, setSortField] = useState<SortField>(null);
    const [sortDirection, setSortDirection] = useState<SortDirection>(null);

    const {podcastId} = useParams()
    const navigate = useNavigate()
    const [selectedTranscriptionStates, setSelectedTranscriptionStates] = useState<TranscriptionState[]>([]);
    const [selectedYoutubeStatuses, setSelectedYoutubeStatuses] = useState<string[]>([]);
    const [sessionId, setSessionId] = useState<string>();
    const createReviewSessionReq = useApi(ENDPOINTS.PUBLISHER_CREATE_REVIEW_SESSION.concat(sessionId as string), user.token, false)
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId as string)
    const [sessions, setSessions] = useState<TranscriptionSession[]>([])
    const [sendingInvite, setSendingInvite] = useState<boolean>(false);
    const toast = useToast()
    const { data: cart = [], isLoading: isCartLoading } = useCart();
    const queryClient = useQueryClient();


    const toggleSort = (field: SortField) => {
        if (sortField === field) {
            if (sortDirection === 'asc') {
                setSortDirection('desc');
            } else if (sortDirection === 'desc') {
                setSortField(null);
                setSortDirection(null);
            }
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const getYoutubeStatus = (episode: PodcastEpisode) => {
        if (!episode.youtube_meta?.is_mapped) {
            return { label: 'Episode not mapped', color: 'gray' };
        }
        return episode.youtube_meta.captions && episode.youtube_meta.captions.length > 0
            ? { label: 'Published', color: 'green' }
            : { label: 'Not Published', color: 'red' };
    };

    function CaptionLanguageArrow(captions: any) {
        const avatars = captions.map((caption: any) => (
            <Avatar key={caption.language} name={caption.language} color="initials" allowedInitialsColors={['blue', 'red']} />
        ));
        return <Group>{avatars}</Group>;
    }

    const sortedEpisodes = useMemo(() => {
        let filtered = [...episodes];

        if (selectedTranscriptionStates.length > 0) {
            filtered = filtered.filter(episode =>
                selectedTranscriptionStates.includes(episode.transcription.state)
            );
        }

        // Apply YouTube status filter
        if (selectedYoutubeStatuses.length > 0) {
            filtered = filtered.filter(episode => {
                const youtubeStatus = getYoutubeStatus(episode);
                return selectedYoutubeStatuses.includes(youtubeStatus.label);
            });
        }

        // Apply sorting
        if (!sortField || !sortDirection) {
            return filtered.sort((a, b) =>
                new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
            );
        }

        return filtered.sort((a, b) => {
            if (sortField === 'duration') {
                return sortDirection === 'asc'
                    ? a.duration - b.duration
                    : b.duration - a.duration;
            }

            if (sortField === 'pubDate') {
                const dateA = new Date(a.pubDate).getTime();
                const dateB = new Date(b.pubDate).getTime();
                return sortDirection === 'asc'
                    ? dateA - dateB
                    : dateB - dateA;
            }

            return 0;
        });
    }, [episodes, sortField, sortDirection, selectedTranscriptionStates, selectedYoutubeStatuses]);

    useEffect(() => {
        if (!isLoadingTranscriptionSession && transcriptionSessions.length > 0) {
            setSessions(transcriptionSessions);
        } else {

        }
    }, [isLoadingTranscriptionSession, transcriptionSessions]);

    useEffect(() => {
        if (!createReviewSessionReq.loading && createReviewSessionReq.state === apiStates.SUCCESS) {
            queryClient.invalidateQueries({queryKey: ['episodes']});
            toast({
                title: 'Success',
                description: 'Successfully sent to editor',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [createReviewSessionReq.loading])


    const startEditorReviewSession =  (episode: any) => {
        const session: any = transcriptionSessions.find((session: any) => session.episode.id === episode.id);
        if (session.id) {
            setSessionId(session.id)
            createReviewSessionReq.setPayloadData({
                episode: episode,
                publisher: user.user
            })
        }
    }

    const rows = sortedEpisodes.map((episode) => {
        const transcriptionState = getTranscriptionStateDisplay(episode.transcription.state);
        const youtubeStatus = getYoutubeStatus(episode);

        return (
            <Table.Tr key={episode.id} component="tr">
                <Tooltip label={'Open Menu'} position={'top'}>
                    <Table.Td style={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/library/${podcastId}/${episode.id}`)}>
                        <Flex align={'center'} justify={'flex-start'} gap="xs">
                            <Text size="sm" fw={500} lineClamp={1}>
                                {episode.title}
                            </Text>
                            {library.isEpisodeInCart(episode.id) ? (
                                <Badge color="grape" variant="light" size="sm">
                                    In Cart
                                </Badge>
                            ) : null
                            }
                        </Flex>
                    </Table.Td>
                </Tooltip>
                <Table.Td>{secondsToMinutes(episode.duration)}</Table.Td>
                <Table.Td>{new Date(episode.pubDate).toLocaleDateString()}</Table.Td>
                <Table.Td>
                    {episode.transcription.state !== TranscriptionState.PROOFREADING_COMPLETED &&
                        <Badge color={transcriptionState.color} variant="light" size="sm">
                            {transcriptionState.label}
                        </Badge>
                    }
                    {episode.transcription.state === TranscriptionState.PROOFREADING_COMPLETED &&
                        <Tooltip label={'Configure Review Session'} position={'bottom'}>
                            <Popover width={'fit-content'} closeOnClickOutside={true} trapFocus={true} position="right" withArrow shadow="md">
                                <Popover.Target>
                                    <Badge color={transcriptionState.color} variant="light" size="sm" style={{ cursor: 'pointer' }} onClick={(e) => e.stopPropagation()}>
                                        {transcriptionState.label}
                                    </Badge>
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Flex w={'full'} p={'md'} direction={'column'} gap={'8px'} align={'center'} justify={'space-between'}>
                                        <Button   onClick={() => {
                                            startEditorReviewSession(episode)
                                        }}>
                                            Send to editor
                                        </Button>
                                    </Flex>
                                </Popover.Dropdown>
                            </Popover>
                        </Tooltip>
                    }
                </Table.Td>
                {podcast?.youtube_connected && (
                    <Table.Td>
                        <Badge color={youtubeStatus.color} variant="light" size="sm">
                            {youtubeStatus.label}
                        </Badge>
                    </Table.Td>
                )}
                <Table.Td>
                    <Badge size="sm" color="orange" variant="light">
                        {episode.transcription.processor || 'None'}
                    </Badge>
                </Table.Td>
            </Table.Tr>
        );
    });

    return (
        <Flex w={'100%'} direction={'column'} gap={'md'}>
            {episodes.length === 0 && <PodcastStudioEmptyLibraryMessageModal mode={'Published'} />}
            {episodes.length > 0 &&
                <Table verticalSpacing="sm" horizontalSpacing="md" striped highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Th currentSort={sortField} direction={null}>
                                Episodes
                            </Th>
                            <Th
                                sortField="duration"
                                currentSort={sortField}
                                direction={sortField === 'duration' ? sortDirection : null}
                                onSort={() => toggleSort('duration')}
                            >
                                Duration (min)
                            </Th>
                            <Th
                                sortField="pubDate"
                                currentSort={sortField}
                                direction={sortField === 'pubDate' ? sortDirection : null}
                                onSort={() => toggleSort('pubDate')}
                            >
                                Upload Date
                            </Th>
                            <Th currentSort={sortField} direction={null}>
                                <Group gap="2px">
                                    <span>Transcription</span>
                                    <TranscriptionFilter
                                        selectedStates={selectedTranscriptionStates}
                                        onFilterChange={setSelectedTranscriptionStates}
                                    />
                                </Group>
                            </Th>
                            {podcast?.youtube_connected && (
                                <Th currentSort={sortField} direction={null}>
                                    <Group gap="2px">
                                        <span>Youtube Caption Status</span>
                                        <YouTubeCaptionStatusFilter
                                            selectedStates={selectedYoutubeStatuses}
                                            onFilterChange={setSelectedYoutubeStatuses}
                                        />
                                    </Group>
                                </Th>
                            )}
                            <Th currentSort={sortField} direction={null}>
                                Source
                            </Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            }
        </Flex>
    );
};
