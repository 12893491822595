import React, {useState} from 'react';
import {Button, Flex} from '@mantine/core';
import {useDisclosure} from "@mantine/hooks";
import PodcastProductionConfigurationModule from "./podcast-production-configuration-module";
import {UnpublishedMediaContent} from "../models/UnpublishedMediaContent";
import { IconFileUpload } from '@tabler/icons-react';
import { Text } from '@mantine/core';
import {PodcastStudioEmptyLibraryMessageModal} from "./components/podcast-empty-library-message-modal";

const PodcastProductionModule = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const [unpublishedMedia, setUnpublishedMedia] = useState<UnpublishedMediaContent[]>([]);


    return (
        <Flex
            w={'100%'}
            direction="column"
            align="flex-start"
            gap="24px"
            style={{
                height: '100%',
                overflow: 'auto'
            }}
        >
            <Flex direction={"row"} w={"100%"} align={"center"} justify={"flex-end"}>
                <Button variant={'outline'} onClick={open} style={{alignSelf: 'flex-end'}}>
                    Add Episode
                </Button>
            </Flex>
            {unpublishedMedia.length === 0 ? (
                <PodcastStudioEmptyLibraryMessageModal mode={'Production'}/>
            ) : (
                <Flex direction="column" w="100%">
                    {/* Episode list content */}
                </Flex>
            )}
            <PodcastProductionConfigurationModule isOpen={opened} onClose={close}/>
        </Flex>
    );
};

export default PodcastProductionModule;
