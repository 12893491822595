import React, {useEffect, useState} from 'react';
import { Modal, TextInput, Paper, Stack, UnstyledButton, Image, Box, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import Fuse from 'fuse.js';
import {useUser} from "../../context/UserContext";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {apiStates, useApi} from "../../api/braincap-api";
import {Center, Spinner, useToast} from "@chakra-ui/react";
import {Podcast} from "../../models/Podcast";
import {YouTubeVideo} from "./youtube-mapping-modal";

interface PlaylistSelectionModalProps {
    opened: boolean;
    close: () => void;
    podcast: Podcast
    youtubePlaylists: YouTubePlaylist[];
}

interface YouTubePlaylist {
    id: string;
    snippet: {
        title: string;
        thumbnails: {
            default: {
                url: string;
            };
        };
    };
}

export const YoutubePlaylistMappingModal: React.FC<PlaylistSelectionModalProps> = ({
                                                                                  opened,
                                                                                  close,
                                                                                  podcast,
                                                                                  youtubePlaylists,
                                                                              }) => {
    const user: any = useUser()
    const libraryNavigation: any = useLibraryNavigation();
    const [step, setStep] = useState<1 | 2>(1);
    const mapYoutubePlaylistToPodcastReq = useApi(`/youtube/playlists/connect/${podcast.id}`, user.token, false)
    const toast: any = useToast()
    const [isMapped, setIsMapped] = useState<boolean>(false)

    useEffect(() => {
        if (!mapYoutubePlaylistToPodcastReq.loading && mapYoutubePlaylistToPodcastReq.state === apiStates.SUCCESS) {

            libraryNavigation.setCurrentPodcast(mapYoutubePlaylistToPodcastReq.data)
            toast({
                title: 'YouTube Mapping',
                description: 'Playlist Mapped Successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
            setIsMapped(true)
            close()
        } else if (!mapYoutubePlaylistToPodcastReq.loading && mapYoutubePlaylistToPodcastReq.error) {
            toast({
                title: 'YouTube Mapping',
                description: 'Error fetching Mapping Playlist Videos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }, [mapYoutubePlaylistToPodcastReq.loading]);

    const playlistForm = useForm({
        initialValues: {
            search: '',
        },
    });

    const playlistFuse = new Fuse(youtubePlaylists, {
        keys: ['snippet.title'],
        threshold: 0.4,
    });

    const filteredPlaylists = playlistForm.values.search
        ? playlistFuse.search(playlistForm.values.search).map((result) => result.item)
        : youtubePlaylists;

    const handlePlaylistSelect = (playlist: any) => {
        mapYoutubePlaylistToPodcastReq.setPayloadData({ playlistId: playlist.id })
        setStep(2);
    };

    const PlaylistItem = ({ playlist }: { playlist: YouTubePlaylist }) => (
        <UnstyledButton
            onClick={() => handlePlaylistSelect(playlist)}
            style={(theme: any) => ({
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing.md,
                borderRadius: theme.radius.sm,
                boxShadow: theme.shadows.sm,
                '&:hover': {
                    backgroundColor: theme.colors.gray[2],
                },
            })}
        >
            <Image
                src={playlist.snippet.thumbnails.default.url}
                width={40}
                height={40}
                radius="sm"
            />
            <Box ml="md">
                <Text size="sm" fw={500}>
                    {playlist.snippet.title}
                </Text>
            </Box>
        </UnstyledButton>
    );

    return (
        <Modal
            opened={opened}
            onClose={close}
            size="xl"
            title={`Select Playlist for ${podcast.title}`}
        >
            {mapYoutubePlaylistToPodcastReq.loading &&
                <Center>
                    <Spinner size={'md'}/>
                </Center>
            }
            {(!mapYoutubePlaylistToPodcastReq.loading && !isMapped) &&
                <>
                    <TextInput
                        placeholder="Search playlists"
                        mb="md"
                        {...playlistForm.getInputProps('search')}
                    />
                    <Paper style={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Stack gap="xs">
                            {filteredPlaylists.map((playlist) => (
                                <PlaylistItem
                                    key={playlist.id}
                                    playlist={playlist}
                                />
                            ))}
                        </Stack>
                    </Paper>
                </>
            }
        </Modal>
    );
};
