import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Badge,
    Button,
    Card,
    Flex,
    Group,
    Image,
    Loader,
    Pill, Progress,
    RingProgress,
    Text,
    useMantineTheme
} from '@mantine/core';
import {EpisodesIcon} from "../assets/EpisodesIcon";
import {useEpisodes, useFetchRss} from "../hooks/usePodcastLibraryQueries";
import {useParams} from "react-router-dom";
import {TranscriptionState} from "../models/Localization";
import {LocalizationProgressFilter} from "./episode/episode-module";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import {Podcast} from "../models/Podcast";
import {Toast, useToast} from "@chakra-ui/react";
import {CheckIcon, WarningIcon} from "@chakra-ui/icons";
import {useUser} from "../context/UserContext";
import {useDisclosure} from "@mantine/hooks";
import {useApi} from "../api/braincap-api";
import {YoutubeChannelInfo} from "../models/Youtube";
import {YoutubePlaylistMappingModal} from "./youtube/youtube-playlist-mapping-modal";
import {YoutubeEpisodeDisplayModal} from "./youtube/youtube-episodes-mapping-modal";
import {BraincapYoutubeBadge} from "../assets/library-tags";
import {IconBrandYoutube, IconRss} from "@tabler/icons-react";
import {PodcastEpisode} from "../models/PodcastEpisode";

export const PodcastLocalizationProgress = ({ step, setFilter }: { step: string, setFilter: any }) => {
    const { podcastId } = useParams();
    const { data: podcastEpisodesData, isLoading: episodesLoading } = useEpisodes(podcastId as string);
    const [totalCompleted, setTotalCompleted] = React.useState(0);
    const [totalInProgress, setTotalInProgress] = React.useState(0);
    const [totalNotStarted, setTotalNotStarted] = React.useState(0);
    const theme = useMantineTheme();

    const episodes = useMemo(() => podcastEpisodesData || [], [podcastEpisodesData]);

    useEffect(() => {
        if (!episodesLoading && episodes.length > 0) {
            switch (step) {
                case 'Transcription':
                    setTotalCompleted(episodes.filter(episode => episode.transcription.state === TranscriptionState.COMPLETED).length);
                    setTotalNotStarted(episodes.filter(episode => episode.transcription.state === TranscriptionState.NOT_CREATED).length);
                    setTotalInProgress(episodes.filter(episode => episode.transcription.state !== TranscriptionState.COMPLETED && episode.transcription.state !== TranscriptionState.NOT_CREATED).length);
                    break;
                case 'Translation':
                    setTotalCompleted(episodes.filter(episode => episode.translation.completed.length > 0).length);
                    setTotalInProgress(episodes.filter(episode => episode.translation.processing.length > 0).length);
                    setTotalNotStarted(episodes.filter(episode => episode.translation.completed.length === 0 && episode.translation.processing.length === 0).length);
                    break;
                case 'Dubbing':
                    setTotalCompleted(episodes.filter(episode => episode.dubbing.completed.length > 0).length);
                    setTotalInProgress(episodes.filter(episode => episode.dubbing.processing.length > 0).length);
                    setTotalNotStarted(episodes.filter(episode => episode.dubbing.completed.length === 0 && episode.dubbing.processing.length === 0).length);
            }
        }
    }, [podcastEpisodesData, episodes, step]);

    const handleFilterChange = (filterType: string) => {
        switch (step) {
            case 'Transcription':
                setFilter(LocalizationProgressFilter.TRANSCRIPTION_COMPLETED)
                break;
            case 'Translation':
                setFilter(LocalizationProgressFilter.TRANSLATION_COMPLETED)
                break;
            case 'Dubbing':
                setFilter(LocalizationProgressFilter.DUBBING_COMPLETED)
                break;
            default:
                setFilter('all');
                break;
        }
    };

    const total = episodes.length;
    const completionPercentage = total > 0 ? (totalCompleted / total) * 100 : 0;

    return (
        <Card
            w={{ base: '100%', md: '33%' }}
            withBorder
            radius="md"
            style={{ alignItems: 'center', gap: '16px' }}
        >
            <Text
                fw={500}
                lh={'20px'}
                style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '16px'}}
                c={'#2D3748'}
            >
                {step}
            </Text>

            <RingProgress
                w={'96px'}
                h={'96px'}
                thickness={5}
                roundCaps
                sections={[
                    { value: completionPercentage, color: '#38B2AC' }
                ]}
                label={
                    <Flex ta="center" direction={'column'} gap={'2px'} >
                        <Text fw={600} lh={'16px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '12px'}} c={'#2D3748'}>
                            {Math.round(completionPercentage)}%
                        </Text>
                        <Text fw={400} lh={'16px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '9px'}} c={'#4A5568'}>
                            Completed
                        </Text>
                    </Flex>
                }
            />
            <Flex w="100%" justify={'space-between'} align={'center'}>
                <StatBox
                    value={totalCompleted}
                    label="Completed"
                    onClick={() => handleFilterChange('COMPLETED')}
                />
                <StatBox
                    value={totalInProgress}
                    label="In Progress"
                    onClick={() => handleFilterChange('IN_PROGRESS')}
                />
                <StatBox
                    value={totalNotStarted}
                    label="Remaining"
                    onClick={() => handleFilterChange('NOT_STARTED')}
                />
            </Flex>
        </Card>
    );
};

const StatBox = ({ value, label, onClick }: { value: number, label: string, onClick: () => void }) => (
    <Flex
        w={'100%'}
        direction={'column'}
        display={'flex'}
        justify={'center'}
        align={'center'}
        p={'0px 24px'}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
    >
        <Text ta="center" fw={600} lh={'16px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '12px'}} c={'#2D3748'}>
            {value}
        </Text>
        <Text w={'full'} ta="center" fw={400} lh={'16px'} style={{alignSelf: 'stretch', fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '9px', whiteSpace: 'nowrap'}} c={'#4A5568'}>
            {label}
        </Text>
    </Flex>
);


export const RssLocalizationProgress = ({ setProgressFilter, podcast }: { setProgressFilter: any, podcast: Podcast }) => {
    const user: any = useUser()
    const { mutate: fetchRss, isSuccess, isError, status  } = useFetchRss();
    const [opened, { open, close }] = useDisclosure(false);
    const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
    const youtubeLoginReq = useApi(`/youtube/login/${podcast?.id}`, user.token, false)
    const youtubeChannelInfoReq = useApi(`/youtube/channel/info/${podcast?.id}`, user.token, false)
    const [channelInfo, setChannelInfo] = useState<YoutubeChannelInfo>()
    const [youtubePlaylists, setYoutubePlaylists] = useState<any>([])
    const toast: any = useToast()
    const [mappingProgress, setMappingProgress] = useState<any>(0)

    const {data: episodeData, isLoading: episodesLoading} = useEpisodes(podcast?.id as string)


    useEffect(() => {
        if (!episodesLoading && episodeData && episodeData.length > 0) {
            const nonMappedEp = episodeData.filter((episode: any) => !episode.youtube_meta?.is_mapped)
            setMappingProgress(episodeData.length - nonMappedEp.length)

        }
    }, [episodeData, episodesLoading]);

    useEffect(() => {
        if (isSuccess) {
            toast.closeAll();
            toast({
                title: 'Success',
                description: 'RSS feed fetched successfully',
                status: 'success',
                duration: 3000,
                icon: <CheckIcon/>,
                isClosable: true,
            });
        }

        if (isError) {
            toast.closeAll();
            toast({
                title: 'Error',
                description: 'Error fetching RSS feed',
                status: 'error',
                duration: 3000,
                icon: <WarningIcon/>,
                isClosable: true,
            });
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (!youtubeLoginReq.loading && youtubeLoginReq.data) {
            Toast({
                title: 'YouTube Login',
                description: 'Redirecting to YouTube for login',
                status: 'success',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed')
                }
            })
            let url = youtubeLoginReq.data.url

            if (url) {
                window.location.href = url
            }
        } else if (!youtubeLoginReq.loading ) {
            Toast({
                title: 'YouTube Login Error',
                description: youtubeLoginReq.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed')
                }
            })
        }
    }, [youtubeLoginReq.loading]);

    useEffect(() => {
        if (!youtubeChannelInfoReq.loading && youtubeChannelInfoReq.data) {
            setChannelInfo(youtubeChannelInfoReq.data.channelInfo)
            setYoutubePlaylists(youtubeChannelInfoReq.data.playlists)
            open()
        } else if (!youtubeChannelInfoReq.loading) {
            toast({
                title: 'YouTube Channel Info',
                description: 'Error fetching YouTube Channel Info',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }, [youtubeChannelInfoReq.loading]);

    const fetchRSSFeed = useCallback(() => {
        if (podcast) {
            fetchRss({ rss_feed: podcast.rss_feed, publisher: user, podcastId: podcast.id });
        }
    }, [podcast]);

    const connectToYoutube = useCallback(() => {
        if (podcast) {
            youtubeLoginReq.execute()
        }
    }, [podcast]);

    return (
        <Flex direction={{ base: 'column', md: 'row' }} align="stretch" gap="md" w="100%">
            <Card w={{ base: '100%', md: '500px' }} withBorder radius="md" style={{ flexShrink: 0 }}>
                <Flex w={'100%'} direction={'row'} gap="20px" p="20px" style={{alignItems: 'center', justifyContent: 'flex-start'}}>
                    <Image src={podcast?.image_url} radius="md" w={'162px'} h={'162px'}/>
                    <Flex h={'100%'} direction="column" gap="16px" style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                        <Text fw={500} lh={'24px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '20px'}} c={'#2D3748'}>
                            {podcast?.title}
                        </Text>
                        <Flex w={'100%'} gap="xs" direction={'row'} align={'center'} justify={'flex-start'}>
                            <EpisodesIcon />
                            <Text fw={400} lh={'20px'} style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontSize: '14px', whiteSpace: 'nowrap'}} c={'#4A5568'}>
                                {podcast?.episodes.length} Episodes
                            </Text>
                        </Flex>
                        {status === 'pending' && <Loader size="xs"/>}
                        {status !== 'pending' &&
                            <Badge
                                leftSection={<IconRss size={14} color={'orange'} />}
                                variant={'outline'}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#DCEBFD',
                                    padding: '2px 6px',
                                }}
                                styles={{
                                    root: {
                                        borderRadius: '6px',
                                        borderColor: 'transparent'
                                    },
                                    label: {
                                        color: '#2144AC'
                                    }
                                }}
                                onClick={() => {
                            fetchRSSFeed()
                        }}>
                            Fetch RSS
                        </Badge>
                        }
                        {(!!podcast && podcast?.youtube_connected === false) && (
                            <Button fullWidth onClick={connectToYoutube} color={'teal'} size={'xs'} variant={'outline'}>
                                Connect to Youtube
                            </Button>
                        )}

                        {podcast?.youtube_connected !== undefined && podcast?.youtube_connected && !podcast?.youtube_meta.is_mapped && (
                            <Pill color="red" size="sm" radius="xl" onClick={() => {
                                youtubeChannelInfoReq.execute()
                                toast({
                                    title: 'YouTube Mapping',
                                    description: 'Fetching YouTube playlists Info',
                                    status: 'info',
                                    duration: 9000,
                                    isClosable: true,
                                    position: 'top-right'
                                })
                            }}>
                                Map Youtube Playlist
                            </Pill>
                        )}
                        {podcast?.youtube_connected !== undefined && podcast?.youtube_connected && podcast?.youtube_meta.is_mapped && (
                            <>
                                {!episodesLoading && episodeData && episodeData.length > 0 &&
                                    <>
                                        <Badge
                                            variant={'outline'}
                                            leftSection={<IconBrandYoutube size={14} color={'red'} />}
                                            size="sm"
                                            onClick={() => {
                                            open2()
                                            toast({
                                                title: 'YouTube Episodes Mapping',
                                                description: 'Fetching Playlist videos',
                                                status: 'info',
                                                duration: 9000,
                                                isClosable: true,
                                                position: 'top-right'
                                            })
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                            backgroundColor: '#DCEBFD',
                                            padding: '2px 6px',
                                            }}
                                            styles={{
                                                root: {
                                                    borderRadius: '6px',
                                                    borderColor: 'transparent'
                                                },
                                                label: {
                                                    color: '#2144AC',
                                                    fontStyle: 'normal',
                                                    fontSize: '10px',
                                                    fontFamily: 'sans-serif',
                                                    fontWeight: 600,
                                                    lineHeight: '20px'
                                                }
                                            }}
                                        >
                                            {`Youtube ${mappingProgress}/${episodeData.length} episodes mapped`}
                                        </Badge>
                                    </>
                                }
                            </>
                        )}
                    </Flex>
                </Flex>
            </Card>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                gap="md"
                style={{ flex: 1 }}
                display={{ base: 'none', md: 'flex' }}
            >
                <PodcastLocalizationProgress step="Transcription" setFilter={setProgressFilter} />
                <PodcastLocalizationProgress step="Translation" setFilter={setProgressFilter} />
                <PodcastLocalizationProgress step="Dubbing" setFilter={setProgressFilter} />
            </Flex>
            {channelInfo && podcast?.youtube_connected !== undefined && podcast?.youtube_connected && (
                <YoutubePlaylistMappingModal opened={opened} close={close} podcast={podcast as Podcast} youtubePlaylists={youtubePlaylists} />
            )}
            {episodeData && podcast?.youtube_connected !== undefined && podcast?.youtube_connected && podcast?.youtube_meta.is_mapped && (
                <YoutubeEpisodeDisplayModal opened={opened2} close={close2} podcast={podcast as Podcast} />
            )}
        </Flex>
    );
};
