import {Box, Button, Center, Flex, Slide, Spinner, useToast} from "@chakra-ui/react";
import {GuestConfiguration, HostConfiguration} from "../configuration/speakers-configuration";
import {PublisherEditingConfiguration} from "../configuration/publisher-editing-configuration";
import {ServiceConfiguration} from "../configuration/service-configuration";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useMemo, useState} from "react";
import {BraincapService, ServiceType} from "../../models/Localization";
import {LanguageConfiguration} from "../configuration/language-configuration";
import {useUser} from "../../context/UserContext";
import {useLocalization} from "../../context/LocalizationContext";
import {PodcastCartItem} from "../../models/Cart";
import {useAddToCart, useCart} from "../../hooks/useCartQueries";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {useQueryClient} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {useEpisodes} from "../../hooks/usePodcastLibraryQueries";

export const ConfigurationModule = () => {
    const user: any = useUser()
    const localization: any = useLocalization()
    const libraryNavigation: any = useLibraryNavigation()
    const [selectedService, setSelectedService] = useState<BraincapService>()
    const [validate, setValidate] = useState<boolean>(false)
    const [readyToCheckout, setReadyToCheckout] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isInCart, setIsInCart] = useState<boolean>(false)
    const toast = useToast()
    const [cartMap, setCartMap] = useState<any>({})
    const { data: cartData = [] , isLoading: cartLoading, isError: cartError} = useCart()
    const {mutate: addItemToCart, isSuccess, isError} = useAddToCart()
    const queryClient = useQueryClient();
    const {episodeId, podcastId} = useParams()
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId as string);
    const [episode, setEpisode] = React.useState<PodcastEpisode>();
    const navigate = useNavigate()

    const cart = useMemo(() => cartData || [], [cartData]);

    useEffect(() => {
        if (!episodesLoading && podcastEpisodesData && podcastEpisodesData.length > 0) {
            const episode = podcastEpisodesData.find((ep: any) => ep.id === episodeId);
            setEpisode(episode);

        }
    }, [episodesLoading, podcastEpisodesData, episodeId]);

    useEffect(() => {
        if (episode) {
            localization.configureSingleEpisode(true, episode)
        }
    }, [episode]);

    useEffect(() => {
        console.log(episode?.id)
    }, [episode]);

    useEffect(() => {
        if (cart.length > 0) {
            let mapping: Map<string, PodcastCartItem> = new Map<string, PodcastCartItem>()

            cart.forEach((cartItem: PodcastCartItem) => {
                mapping.set(cartItem.episode.id as string, cartItem)
            })
            setCartMap(mapping)
        }
    }, [cart]);

    useEffect(() => {
        if (!localization.addItemsToCartReq.loading && localization.addItemsToCartReq.data) {
            toast({
                title: 'Success',
                description: 'Item added to cart',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setLoading(false)
            localization.resetConfiguration()
            queryClient.invalidateQueries({queryKey: ['cart']});
            queryClient.invalidateQueries({queryKey: ['episodes']});
            libraryNavigation.setDisplaySidebar(false)
            navigate(`/cart/${podcastId}`)
        }

        if (!localization.addItemsToCartReq.loading && localization.addItemsToCartReq.error) {
            toast({
                title: 'Error',
                description: 'Error adding item to cart',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            setLoading(false)
        }

    }, [localization.addItemsToCartReq.loading])

    useEffect(() => {
        if (episode) {

            if (cartMap.size > 0 && !cartLoading) {
                if (cartMap.has(episode.id)) {
                    setIsInCart(true)
                } else {
                    setIsInCart(false)
                }
            }

            if (cartMap.size === 0) {
                setIsInCart(false)
            }
        }
    }, [cartLoading, cart])

    useEffect(() => {
        if (selectedService) {
            setValidate(false)
        }
    }, [selectedService])

    const handleAddToCart = async () => {
        if (!selectedService) {
            toast({
                title: 'Invalid configuration',
                description: 'Please select a service',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        if (localization.validateConfiguration()) {
            setValidate(true)
            toast({
                title: 'Invalid configuration',
                description: 'Please complete all required fields',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return
        } else if (isInCart) {
            toast({
                title: 'Episode already in cart',
                description: 'You can only have one service per episode in your cart',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        else {
            toast({
                title: 'Success',
                description: 'Configuration is valid',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setLoading(true)
            localization.handleAddToCart()
        }

    }


return (
    <Flex w={'full'} h={'100vh'} direction={'column'}>
        <Flex
            w={'full'}
            flex={1}
            direction={'column'}
            gap={'12px'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            overflowY="auto"
            p={'16px'}
        >
            <ServiceConfiguration episode={episode as PodcastEpisode} setSelectedService={setSelectedService} />
            {selectedService && (
                <>
                    {selectedService?.type && (
                        <LanguageConfiguration episode={episode as PodcastEpisode} service={selectedService} validate={validate} />
                    )}
                    {(selectedService?.type === ServiceType.TRANSLATION || selectedService?.type === ServiceType.DUBBING) && (
                        <PublisherEditingConfiguration episode={episode as PodcastEpisode} service={selectedService} validate={validate} />
                    )}
                    {selectedService?.type && <HostConfiguration validate={validate} episode={episode as PodcastEpisode} />}
                    {selectedService?.type && <GuestConfiguration validate={validate} episode={episode as PodcastEpisode} />}
                </>
            )}
        </Flex>
        {selectedService &&
            <Flex
                position="sticky"
                bottom={0}
                w="100%"
                h={'90px'}
                bg="white"
                boxShadow={'lg'}
                justifyContent="center"
                alignItems="center"
                zIndex={2}
            >
                <Center>
                    {!loading ? (
                        <Button
                            isDisabled={loading}
                            onClick={handleAddToCart}
                            w={'100%'}
                            size="md"
                            colorScheme="teal"
                        >
                            Add to Cart
                        </Button>
                    ) : (
                        <Spinner size="md" color="teal.500" thickness="4px" />
                    )}
                </Center>
            </Flex>
        }
    </Flex>
)

}
