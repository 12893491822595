import React, {createContext, useContext, useEffect, useState} from "react";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {useAuth} from "./AuthContext";
import {User, UserCategory} from "../models/Users";
import {NotificationSettings} from "../models/BraincapNotification";
import {useToast} from "@chakra-ui/react";

export const UserContext = createContext(null);

export default function UserProvider(props: any) {
    const auth: any = useAuth()
    const token = auth.token
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState<string>('')
    const [user, setUser] = useState<User>()
    const [saveNotifications, setSaveNotifications] = useState<boolean>(false)
    const [notifications, setNotifications] = useState<Map<string, NotificationSettings>>(new Map<string, NotificationSettings>())
    const [notificationsList, setNotificationsList] = useState<NotificationSettings[]>([])
    const toast = useToast()

    const userDataReq = useApi(ENDPOINTS.USER_INFO, token, false)
    const userNotificationUpdateReq = useApi(ENDPOINTS.PUBLISHER_UPDATE_NOTIFICATIONS.concat(userId), token, false)

    useEffect(() => {
        if (!!token && !userId && !auth.loading) {
            userDataReq.execute()
        }
    }, [token, auth.loading])

    useEffect(() => {
        if (!auth.loading && userDataReq.data) {
            if (userDataReq.data.category === UserCategory.PUBLISHER) {
                if (userDataReq.data.notificationSettings) {
                    let currentNotifications: Map<string, NotificationSettings> = new Map<string, NotificationSettings>()
                    let currentNotificationsList: NotificationSettings[] = []
                    Object.values(userDataReq.data.notificationSettings).forEach((notification: any) => {
                        currentNotifications = {
                            ...currentNotifications,
                            [notification.name]: notification
                        }
                        currentNotificationsList.push(notification)
                    })
                    setNotifications(currentNotifications)
                    setNotificationsList(currentNotificationsList)
                }
                setUserId(userDataReq.data.id)
                setUser(userDataReq.data)
                setLoading(false)
            } else if (userDataReq.data.category === UserCategory.EDITOR) {
                setUserId(userDataReq.data.id)
                setUser(userDataReq.data)
                setLoading(false)
            }
        }

        if (!auth.loading && userDataReq.error) {
            setLoading(false)
        }
    }, [userDataReq.loading])

    useEffect(() => {
        if (saveNotifications && userNotificationUpdateReq.loading) {
            userNotificationUpdateReq.setPayloadData({
                notificationSettings: notifications
            })
        } else {
            setSaveNotifications(false)
        }

    }, [saveNotifications]);

    return (
        <UserContext.Provider value = {{
            token: token,
            userId: userId,
            user: user,
            setUser: setUser,
            loading: loading,
            userDataReq: userDataReq,
            notifications: notifications,
            setNotifications: setNotifications,
            notificationsList: notificationsList,
            saveNotifications: saveNotifications,
            setSaveNotifications: setSaveNotifications,
        }} {...props} />
    )
}

export const useUser = () => useContext(UserContext)



