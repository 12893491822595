import React, {useEffect, useState} from "react";
import {SpeakerInvitation, SpeakerType} from "../models/Speakers";
import {useForm} from "@mantine/form";
import {convertSecondsToMinutes} from "../utils/firebase";
import {UploadDropzone} from "@bytescale/upload-widget-react";
import {Flex, Modal, Text, TextInput} from "@mantine/core";
import {MediaSpeakerInput} from "../components/configuration/speakers-configuration";
import {BraincapService, LocalizationProcessingType, ServiceType} from "../models/Localization";
import {useLocalization} from "../context/LocalizationContext";
import {ProductionServiceComponent} from "../components/configuration/service-configuration";
import {Box, Button, Center, useToast, VStack,} from "@chakra-ui/react";
import {UnpublishedMediaContentCartItem} from "../models/Cart";
import {useUser} from "../context/UserContext";
import {useParams} from "react-router-dom";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import {Podcast, PodcastLocalizationMeta} from "../models/Podcast";
import {useAddToCart, useAddToProductionCart, useProductionCart} from "../hooks/useCartQueries";

export default function PodcastProductionConfigurationModule({
                                                                 isOpen,
                                                                 onClose
                                                             }: {
    isOpen: boolean;
    onClose: any;
}) {
    const user: any = useUser()
    const {podcastId} = useParams()
    const {podcasts} = usePodcastLibrary()
    const [currentPodcast, setCurrentPodcast] = useState<Podcast>();
    const toast = useToast();
    const localization: any = useLocalization();
    const [title, setTitle] = useState("");
    const [sourceLanguage, setSourceLanguage] = useState<string>("");
    const [allSpeakers, setAllSpeakers] = useState<SpeakerInvitation[]>([]);
    const [mediaUrl, setMediaUrl] = useState("");
    const [duration, setDuration] = useState(0);
    const [service, setService] = useState<BraincapService>();
    const {mutate: addItemToCart, isSuccess, isError} = useAddToProductionCart()


    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            const transcriptionService = localization.services.get(
                ServiceType.TRANSCRIPTION
            );
            setService(transcriptionService);
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded]);

    useEffect(() => {
        if (podcasts.length > 0) {
            const podcast = podcasts.find((p: any) => p.id === podcastId);
            setCurrentPodcast(podcast);
        }
    }, [podcasts]);

    const options = {
        apiKey: "public_12a1zBMFJNhXjLF6NP3CJSyuot6p",
        maxFileCount: 1,
        showFinishButton: true,
        styles: {
            colors: {
                primary: "#377dff"
            }
        },
        mimeTypes: [
            "audio/aac",
            "audio/mp3",
            "audio/mpeg",
            "audio/ogg",
            "audio/wav",
            "audio/webm",
            "audio/x-m4a",
            "video/mp4",
            "video/mpeg",
            "video/quicktime",
            "video/webm",
            "video/x-msvideo",
            "video/ogg"
        ]
    };

    const form = useForm({
        initialValues: {
            title: "",
            mediaUrl: "",
        },
        validate: {
            title: (value) => (!value ? "Title is required" : null),
            mediaUrl: (value) => (!value ? "Media URL is required" : null),
        }
    });

    function AudioDuration({ url }: { url: string }) {
        useEffect(() => {
            const audio = new Audio();
            audio.src = url;

            const onMetadataLoaded = () => {
                setDuration(audio.duration);
                audio.removeEventListener("loadedmetadata", onMetadataLoaded);
            };

            audio.addEventListener("loadedmetadata", onMetadataLoaded);

            return () => {
                audio.removeEventListener("loadedmetadata", onMetadataLoaded);
            };
        }, [url]);

        return (
            <div>
                {duration ? (
                    <p>Duration: {convertSecondsToMinutes(duration)} minutes</p>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    }

    const BraincapContentUploader = () => (
        <UploadDropzone
            options={options}
            onUpdate={({ uploadedFiles }) =>
                console.log(uploadedFiles.map((x) => x.fileUrl).join("\n"))
            }
            onComplete={(files) => {
                setMediaUrl(files[0].fileUrl);
                form.setFieldValue("mediaUrl", files[0].fileUrl);
            }}
            width="600px"
            height="375px"
        />
    );

    const validateAndSubmit = () => {
        const formValidation = form.validate();

        const validationErrors = [];

        if (!form.values.title) {
            validationErrors.push("Media title is required");
        }

        if (!mediaUrl) {
            validationErrors.push("Media file is required");
        }

        if (!allSpeakers.some(speaker => speaker.invited_as === SpeakerType.HOST)) {
            validationErrors.push("Host speaker is required");
        }

        if (!allSpeakers.some(speaker => speaker.invited_as === SpeakerType.GUEST)) {
            validationErrors.push("Guest speaker is required");
        }

        if (validationErrors.length > 0) {
            toast({
                title: "Validation Error",
                description: validationErrors.join("\n"),
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
            return;
        }

        // If validation passes, proceed with add to cart
        toast({
            title: "Success",
            description: "Added to cart successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top"
        });

        const channel: PodcastLocalizationMeta = {
            id: podcastId,
            title: currentPodcast?.title as string,
            rss_feed: currentPodcast?.rss_feed as string,
            summary: currentPodcast?.summary as string,
            publisher: currentPodcast?.publisher as any,
            categories: currentPodcast?.categories as any,
        }

        const cartItem: UnpublishedMediaContentCartItem = {
            service: service as BraincapService,
            customer: {
                id: user.userId,
                name: user.user.name,
                email: user.user.email,
                phone: user.user.phone,
                category: user.user.category,
            },
            speakers: allSpeakers,
            source_language: {
                code: 'en',
                name: 'English'
            },
            channel: channel,
            processing_type: LocalizationProcessingType.PRE_PUBLISHING,
            media: {
                id : '',
                created_at: '',
                title: form.values.title,
                media_URL: mediaUrl,
                duration: duration,
                speakersConfig: allSpeakers,
                channel: channel,
                publisher: {
                    id: user.userId,
                    name: user.user.name,
                    email: user.user.email,
                },

            }
        };

        addItemToCart({
            cart: cartItem,
            publisherId: user.userId,
        });

        // Add your cart logic here
    };

    useEffect(() => {
        //display toast based on result
        if (isSuccess) {
          toast({
              title: "Success",
              description: "Added to cart successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top"
          });
          onClose()
        }

        if (isError) {
            toast({
                title: "Error",
                description: "Failed to add to cart",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        }
    }, [isSuccess, isError]);

    return (
        <Modal opened={isOpen} onClose={onClose} title="Podcast Production Configuration" size="2xl">
            <VStack spacing={6} align="stretch" pb="80px">
                {!!service && <ProductionServiceComponent service={service as BraincapService} />}

                <Flex direction="column" gap="12px">
                    <Text size="16px" fw={500} c="dimmed">
                        Media Title
                    </Text>
                    <TextInput
                        {...form.getInputProps("title")}
                        placeholder="Enter media title"
                    />
                </Flex>

                <Box>
                    <BraincapContentUploader />
                </Box>

                <Flex direction="column" gap="12px">
                    <Text size="16px" fw={500} c="dimmed">
                        Media URL
                    </Text>
                    <TextInput
                        {...form.getInputProps("mediaUrl")}
                        placeholder="Enter media URL"
                        value={mediaUrl}
                    />
                    {!!mediaUrl && <AudioDuration url={mediaUrl} />}
                </Flex>

                <Flex direction="column" gap="12px">
                    <Text size="16px" fw={500} c="dimmed">
                        Host
                    </Text>
                    <MediaSpeakerInput
                        type={SpeakerType.HOST}
                        allSpeakers={allSpeakers}
                        setAllSpeakers={setAllSpeakers}
                    />
                </Flex>

                <Flex direction="column" gap="12px">
                    <Text size="16px" fw={500} c="dimmed">
                        Guest
                    </Text>
                    <MediaSpeakerInput
                        type={SpeakerType.GUEST}
                        allSpeakers={allSpeakers}
                        setAllSpeakers={setAllSpeakers}
                    />
                </Flex>
            </VStack>

            <Box
                position="fixed"
                p={4}
                bg="white"
                borderTop="1px solid"
                borderColor="gray.200"
                zIndex={2}
            >
                <Center>
                    <Button
                        width="95%"
                        colorScheme="teal"
                        size="lg"
                        onClick={validateAndSubmit}
                    >
                        Add to Cart
                    </Button>
                </Center>
            </Box>
        </Modal>
    );
}
