import CartPanel from "../../components/cart/cart-panel";
import {Flex} from "@mantine/core";
import {ChevronLeftIcon, CloseIcon} from "@chakra-ui/icons";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";

export default function CartSidebar() {
    const navigate = useNavigate()
    const {episodeId, podcastId} = useParams()

    return (
        <Flex w={'100%'} h={'100%'} direction="column" style={{ alignSelf: 'stretch' }}>
            <Flex w="100%" direction="row" align="center" justify="flex-end" gap="md">
                <CloseIcon w="24px" h="24px" onClick={() => navigate(`/library/${podcastId}`)} cursor="pointer"/>
            </Flex>
            <CartPanel/>
        </Flex>
    )
}
