import apiClient, {PODCASTLIBRARYENDPOINTS} from "../api/braincap-api-client";
import {PodcastCartItem} from "../models/Cart";
import {UnpublishedMediaContentTranscriptionConfig} from "../models/UnpublishedMediaContent";

export const CartService = {
    getCart: async (userId: string): Promise<PodcastCartItem[]> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.CART}/${userId}`);
        return response.data;
    },
    addToCart: async (data: { cart: PodcastCartItem[], publisherId: any }): Promise<any> => {
        const response = await apiClient.post(`${PODCASTLIBRARYENDPOINTS.ADD_TO_CART}/${data.publisherId}`, data);
        return response.data;
    },

    removeFromCart: async (data: { episodeId: string, publisherId: string }): Promise<any> => {
        const response = await apiClient.post(PODCASTLIBRARYENDPOINTS.REMOVE_FROM_CART, data);
        return response.data;
    },
    getProductionCart: async (userId: string): Promise<PodcastCartItem[]> => {
        const response = await apiClient.get(`cart/production/${userId}`);
        return response.data;
    },
    addProductionMediaToCart: async (data: { cart: UnpublishedMediaContentTranscriptionConfig, publisherId: any }): Promise<any> => {
        const response = await apiClient.post(`cart/production/add/${data.publisherId}`, data);
        return response.data;
    },

    removeProductionFromCart: async (data: { episodeId: string, publisherId: string }): Promise<any> => {
        const response = await apiClient.post('/cart/production/delete/', data);
        return response.data;
    },
};
