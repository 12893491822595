import apiClient, {PODCASTLIBRARYENDPOINTS} from "../api/braincap-api-client";
import {Podcast} from "../models/Podcast";
import {PodcastEpisode} from "../models/PodcastEpisode";
import {LocalizationSession, TranscriptionSession} from "../models/Localization";

export const PodcastService = {
    getPodcasts: async (userId: string): Promise<Podcast[] | []> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.PODCASTS}/${userId}`);
        return response.data? response.data : []
    },
    getProductionMedia: async (userId: string): Promise<Podcast[] | []> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.PODCASTS}/${userId}`);
        return response.data? response.data : []
    },
    getEpisodes: async (podcastId: string): Promise<{ episodes: PodcastEpisode[], progress: any, transcriptionProgress: any }> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.EPISODES}/${podcastId}`);
        return response.data.episodes.length > 0 ? response.data.episodes : []
    },
    getAllEpisodesTest: async (): Promise<PodcastEpisode[]> => {
        const response = await apiClient.get(`publisher/episodes/all`);
        return response.data;
    },
    getEpisodesInReview: async (userId: string): Promise<TranscriptionSession[]> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.EPISODES_IN_REVIEW}/DeJywyienIQBwckWsjOm3JdMH3t1`);
        return response.data;
    },
    fetchRss: async (data: { rss_feed: string, publisher: any, podcastId: any }): Promise<any> => {
        const response = await apiClient.post(PODCASTLIBRARYENDPOINTS.FETCH_RSS, data);
        return response.data;
    },

    addTagToEpisodes: async (data: { episodes: any[], tag: string }): Promise<any> => {
        const response = await apiClient.post(PODCASTLIBRARYENDPOINTS.ADD_TAG_TO_EPISODES, data);
        return response.data;
    },

    removeTagFromEpisodes: async (data: { episodeId: string, tag: string }): Promise<any> => {
        const response = await apiClient.post(`${PODCASTLIBRARYENDPOINTS.REMOVE_TAG_FROM_EPISODE}/${data.episodeId}`, data);
        return response.data;
    },
};
