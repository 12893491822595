import {Flex} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {TranscriptCompleteEditor} from "../components/transcript-editors/transcript-complete-page";
import {useState} from "react";
import {TranscriptMode} from "../models/Transcript";
import {TranscriptReviewEditor} from "../components/transcript-editors/review-page";
import {TranscriptionTaskStates} from "../studio/transcription-studio";


export default function TranscriptReader({mode, states}: {mode: TranscriptMode, states: TranscriptionTaskStates}) {
    const [loading, setLoading] = useState<boolean>(true);
    const { sessionId, podcastId, episodeId }: any = useParams();


    return (
        <Flex w={'full'} direction={'column'} p={'12px'} gap={'16px'} alignItems={'flex-start'}>
            {(mode === TranscriptMode.ASR ||
                mode === TranscriptMode.PROOFREAD ||
                mode === TranscriptMode.COMPLETED
            ) && <TranscriptCompleteEditor mode={mode} states={states}/>}
            {mode === TranscriptMode.REVIEWING && <TranscriptReviewEditor />}
        </Flex>
    );
};
