import React from 'react';
import {ActionIcon, Checkbox, Flex, Popover} from '@mantine/core';
import {IconFilter} from '@tabler/icons-react';
import {useDisclosure} from "@mantine/hooks";


export const YouTubeCaptionStatusFilter = ({
                                        selectedStates,
                                        onFilterChange
                                    }: {
    selectedStates: string[],
    onFilterChange: (states: string[]) => void
}) => {
    const [opened, { toggle, close }] = useDisclosure(false);

    const youtubeStatusOptions = [
        'Episode not mapped',
        'Published',
        'Not Published'
    ];

    const handleStateChange = (state: string) => {
        const newStates = selectedStates.includes(state)
            ? selectedStates.filter(s => s !== state)
            : [...selectedStates, state];
        onFilterChange(newStates);
    };

    return (
        <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
            <Popover.Target>
                <ActionIcon
                    variant="subtle"
                    color="gray"
                    size="sm"
                    onClick={toggle}
                >
                    <IconFilter size={16} />
                </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
                <Flex direction="column" gap="xs">
                    {youtubeStatusOptions.map(state => (
                        <Checkbox
                            key={state}
                            label={state}
                            checked={selectedStates.includes(state)}
                            onChange={() => handleStateChange(state)}
                        />
                    ))}
                </Flex>
            </Popover.Dropdown>
        </Popover>
    );
};
