import React, { useState } from 'react';
import { Popover, TextInput, Stack, Checkbox, Group, Box } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import {TranscriptionState} from "../../models/Localization";

const transcriptionStates = [
    { state: TranscriptionState.NOT_CREATED, label: 'Not Started' },
    { state: TranscriptionState.CREATED, label: 'Created' },
    { state: TranscriptionState.SPEECH_TO_TEXT, label: 'Processing' },
    { state: TranscriptionState.SPEECH_TO_TEXT_RUNNING, label: 'Running AI Transcription'},
    { state: TranscriptionState.READY_FOR_PROOFREADING, label: 'Waiting for Proofreading' },
    { state: TranscriptionState.PROOFREADING, label: 'Transcriptionist Proofreading' },
    { state: TranscriptionState.PROOFREADING_COMPLETED, label: 'Proofreading Completed' },
    { state: TranscriptionState.EDITOR_REVIEW, label: 'Sent to Editor'},
    { state: TranscriptionState.EDITOR_REVIEWING, label: 'Editor Reviewing' },
    { state: TranscriptionState.PUBLISHER_EDITING, label: 'Publisher Editing' },
    { state: TranscriptionState.PUBLISHER_REVIEWING, label: 'Publisher Reviewing' },
    { state: TranscriptionState.EDITOR_REVIEW_COMPLETED, label: 'Editor Review Completed' },
    { state: TranscriptionState.COMPLETED, label: 'Completed' },
];

export const TranscriptionFilter = ({
                                        selectedStates,
                                        onFilterChange
                                    }: {
    selectedStates: TranscriptionState[],
    onFilterChange: (states: TranscriptionState[]) => void
}) => {
    const [opened, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const filteredStates = transcriptionStates.filter(item =>
        item.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    const toggleState = (state: TranscriptionState) => {
        if (selectedStates.includes(state)) {
            onFilterChange(selectedStates.filter(s => s !== state));
        } else {
            onFilterChange([...selectedStates, state]);
        }
    };

    return (
        <Popover
            opened={opened}
            onChange={setOpened}
            position="bottom"
            shadow="md"
        >
            <Popover.Target>
                <Group
                    gap="xs"
                    className="hover:bg-gray-100 px-2 py-1 rounded cursor-pointer"
                    onClick={() => setOpened(true)}
                >
                    <IconFilter size={16} />
                </Group>
            </Popover.Target>

            <Popover.Dropdown>
                <Stack gap="xs" w={300}>
                    <TextInput
                        placeholder="Search states..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.currentTarget.value)}
                        size="xs"
                    />
                    <Box style={{ maxHeight: '300px', overflow: 'auto', justifyContent: 'space-between' }}>
                        {filteredStates.map(({ state, label }) => (
                            <Checkbox
                                key={state}
                                label={label}
                                checked={selectedStates.includes(state)}
                                onChange={() => toggleState(state)}
                                className="mb-2"
                            />
                        ))}
                    </Box>
                </Stack>
            </Popover.Dropdown>
        </Popover>
    );
};

export default TranscriptionFilter;
