import { IconFileUpload } from '@tabler/icons-react';
import {Flex, Text} from '@mantine/core';
import React from "react";

export const PodcastStudioEmptyLibraryMessageModal = ({mode} : {mode: 'Published' | 'Production'}) => {
    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            w="100%"
            py="48px"
            style={{
                backgroundColor: '#F7FAFC',
                borderRadius: '12px',
                border: '1px dashed #E2E8F0'
            }}
        >
            <Flex
                direction="column"
                align="center"
                justify="center"
                gap="16px"
            >
                <Flex
                    align="center"
                    justify="center"
                >
                    <IconFileUpload
                        size={48}
                        color="#718096"
                        style={{ opacity: 0.5 }}
                    />
                </Flex>
                <Flex
                    direction="column"
                    align="center"
                    gap="8px"
                >
                    <Text
                        size="lg"
                        fw={600}
                        c="#2D3748"
                    >
                        {mode === 'Production' && 'No Episodes in Production'}
                        {mode === 'Published' && 'Your Library is empty'}
                    </Text>
                    <Text
                        size="sm"
                        c="#718096"
                        ta="center"
                        style={{ maxWidth: '400px' }}
                    >
                        {mode === 'Production' &&
                            'Get started by adding your first episode to production.\n' +
                            '                        Click the "Add Episode" button above to begin.'
                        }
                        {mode === 'Published' &&
                            'Get started by adding content in your RSS Feed.\n' +
                            '                        Click the "Fetch RSS" button above to begin.'
                        }

                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
