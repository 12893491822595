import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {EpisodeModule} from "../../components/episode/episode-module";
import CartPanel from "../../components/cart/cart-panel";
import {RssLocalizationProgress} from "../../components/progress-components";
import {SegmentedControl, Flex, Text} from "@mantine/core";
import {PodcastPostProductionLibrary} from "./PodcastPostProductionLibrary";
import {Podcast} from "../../models/Podcast";

export default function PodcastDashboard({podcast}: {podcast: Podcast}) {
    const { podcastId } = useParams<{ podcastId: string }>();

    console.log(podcast)
    if (!podcastId) {
        return <div>Loading...</div>;
    }

    return (
        <EpisodeModule podcastId={podcastId} podcast={podcast}/>
    );
}
