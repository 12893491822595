import {useUser} from "../context/UserContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CartService} from "../services/cart-services";
import {BraincapCartItem, PodcastCartItem} from "../models/Cart";
import {UnpublishedMediaContentTranscriptionConfig} from "../models/UnpublishedMediaContent";

export const useCart = () => {
    const user: any = useUser();
    return useQuery({
        queryKey: ['cart', user.userId],
        queryFn: () => CartService.getCart(user.userId),
        enabled: !!user.userId,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
};

export const useAddToCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { cart: PodcastCartItem[], publisherId: any }) => CartService.addToCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cart'] });
            queryClient.invalidateQueries({queryKey: ['episodes']});
        },
    });
};

export const useRemoveFromCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { episodeId: string, publisherId: string }) => CartService.removeFromCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cart']});
        },
    });
};

export const useProductionCart = (user: any)=> {
    return useQuery({
        queryKey: ['productionCart', user.id],
        queryFn: () => CartService.getProductionCart(user.id),
        enabled: !!user.id,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
};

export const useAddToProductionCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { cart: UnpublishedMediaContentTranscriptionConfig, publisherId: any }) =>
            CartService.addProductionMediaToCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['productionCart'] });
        },
    });
};

export const useRemoveFromProductionCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { episodeId: string, publisherId: string }) =>
            CartService.removeProductionFromCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['productionCart'] });
        },
    });
};
